import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuDrawer from "./drawer";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoArrowUpSharp } from "react-icons/io5";
import ImageIcons from '../imageComponent/ImageIcons';
import { windowScroll } from "../utils/windowScroll";

const Layout = ({ children }) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [scrollPosition, setScrollPosition] = useState(false);

  // Curren link
  const pathname = useLocation().pathname;

  // current year
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  // current year

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 400) {
      setScrollPosition(true);
    } else {
      setScrollPosition(false)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <header className="relative z-50 bg-[#2984bd]">
        <div className="container">
          <div className="flex justify-between items-center lg:h-auto h-[90px]">
            <Link to="/" className="flex items-center">
              <img
                src={ImageIcons.logo}
                className="md:h-[60px] h-[50px]"
                alt='Logo'
              />
            </Link>

            <nav className='lg:block hidden'>
              <ul className=" flex items-center lg:flex-nowrap flex-wrap lg:justify-center justify-start justify-left">
                {data.navLinks.map((link, i) => (
                  <li key={i} className="relative group lg:w-auto w-full">
                    <Link to={link.handle} className={`${pathname === link.handle && "!text-[#2984bd] bg-[#fff]"} text-[#fff] text-[18px] lg:inline-block block w-full	lg:py-[33px] py-[10px] xl:px-[25px] lg:px-[17px] px-[15px] hover:bg-[#fff] hover:text-[#2984bd]` }>{link.title}</Link>
                    {link.subLinks && (
                      <ul className="duration-500 left-0 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.2)] group-hover:visible group-hover:h-auto group-hover:overflow-visible grid bg-white text-black md:absolute relative top-[100%] md:w-[280px] w-[100%] z-[1111] invisible overflow-hidden h-0">
                        {link.subLinks.map((item, i) => (
                          <li key={i} className="w-fill">
                            <Link to={item.handle} className={`${pathname === item.handle && "!text-[#fff] bg-[#2984bd]"} border-b w-full inline-block	border-[#3c62e921] text-[16px] hover:bg-[#2984bd] hover:text-white px-[20px] py-[12px] `}>{item.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}

                {/* <Link className="lg:mt-0 mt-[10px] ml-[20px] text-[#2984bd] bg-[#fff] hover:bg-[#ef7f1a] hover:text-[#fff] text-[18px] font-medium rounded-l-[10px] rounded-r-[10px] px-[40px] py-[10px] " to="#">Login</Link> */}
              </ul>
            </nav>
            <div className="inline-block lg:hidden">
              <MenuDrawer navLinks={data.navLinks} />
            </div>
          </div>
        </div>
      </header>
      {children}
      <footer className="bg-[#2984bd] bg-center bg-contain bg-no-repeat" style={{ backgroundImage: `url(${ImageIcons.footerBg})` }}>
        <div className="container">
          <div className="flex md:flex-nowrap flex-wrap md:py-0 py-[25px]">
            <div className="lg:w-[22%] md:w-[28%] w-[100%] md:py-[30px] py-[12px] px-[10px] border-[#ffffff45] md:border-r border-r-none">
              <Link className="text-white hover:text-[#ef7f1a] h-[100%] flex items-center md:justify-center justify-start group" to='tel:+919056111675'><IoCallSharp className='mr-[10px] text-[30px] group-hover:text-white group-hover:scale-[1.2] duration-700 min-w-[30px]' />+91 90561-11675</Link>
            </div>
            <div className="lg:w-[50%] md:w-[40%]  w-[100%] md:py-[30px] py-[12px] px-[10px] border-[#ffffff45] md:border-r border-r-none">
              <p className="mb-0 text-white hover:text-[#ef7f1a] h-[100%] flex items-center md:justify-center justify-start group "><FaLocationDot className='mr-[10px] text-[30px] group-hover:text-white group-hover:scale-[1.2] duration-700 min-w-[30px]' />B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
            </div>
            <div className="lg:w-[28%] md:w-[33%] w-[100%] md:py-[30px] py-[12px] px-[10px] ">
              <Link className="text-white hover:text-[#ef7f1a] h-[100%] flex items-center md:justify-center justify-start group" to='mailto:cammycloud67@gmail.com'><IoMdMail className='mr-[10px] text-[30px] group-hover:text-white group-hover:scale-[1.2] duration-700 min-w-[30px]' />cammycloud67@gmail.com</Link>
            </div>
          </div>
        </div>
        <hr className="border-[#ffffff45]" />
        <div className="container">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 md:py-[50px] py-[30px]">
            <div className="my-[10px] lg:my-[0]">
              <Link to="/" onClick={windowScroll} className="flex items-center">
                <img
                  src={ImageIcons.logo}
                  className="md:h-[60px] h-[50px]"
                  alt='Logo'
                />
              </Link>
              <div className="mt-[24px]">
                <p className="text-[#fff]">Cloud-based CCTV surveillance leverages cloud technology to store and manage video footage captured by security cameras.</p>
              </div>
            </div>   
            {footerLinks.map((link, i) => (
              <div className="my-[10px] lg:my-[0]" key={i}>
                <h3 className="text-white font-medium	pb-[8px] mb-[25px] text-[20px] xl:text-[24px] relative before:content before:left-[0] before:bottom-[0] before:absolute before:h-[1.5px] before:w-[60px] before:bg-[#fff] " >{link.title}</h3>
                <ul className="grid gap-[10px] sm:gap-[12px] md:gap-[15px]">
                  {link.subLinks.map((item, i) => (
                    <li key={i}>
                      <Link to={item.handle} className={`${pathname === item.handle && "!text-[#ef7f1a]"} text-[16px] text-[#fff] hover:text-[#ef7f1a] `} onClick={windowScroll}>{item.link}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-[#fff]">
          <div className="container">
            <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[25px]">
              <p className="text-[16px] mb-0 md:text-left text-center md:w-1/3 w-full">© Copyright {currentYear} MyCloudCam. All Right Reserved.</p>
              <p className="text-[16px] mb-0 lg:text-left lg:w-auto md:w-1/3 w-full text-center">Powered by VP Broadband Private Limited</p>
              <div className="flex gap-[10px] md:w-1/3 md:justify-end max-md:justify-center w-full md:mt-0 mt-[20px]">
                <Link to='https://www.facebook.com/mycloudcam/' target="_blank" className='text-[20px] text-[#fff] w-[40px] h-[40px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'> <FaFacebookF /></Link>
                <Link to='https://twitter.com/My_cloudcam' target="_blank" className='text-[20px] text-[#fff] w-[40px] h-[40px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'> <FaTwitter /></Link>
                <Link to='https://www.linkedin.com/company/my-cloud-cam/?viewAsMember=true' target="_blank" className='text-[20px] text-[#fff] w-[40px] h-[40px] rounded-full bg-[#2984bd] hover:bg-[#ef7f1a] flex justify-center items-center'> <FaLinkedinIn /></Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button className={`' group flex justify-center items-center 2xl:right-[50px] md:right-[30px] duration-500 right-[15px] bg-[#fff] hover:bg-[#ef7f1a] w-[50px] h-[50px] shadow-md shadow-[#a4cae3] rounded-[10px] z-50 ' ${scrollPosition ? 'opacity-100 overflow-visible fixed transition-[0.8s] bottom-[30px]' : 'bottom-[102%]'}`}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}>
        <IoArrowUpSharp className="text-[#2984bd] text-[22px] group-hover:text-[#fff]" />
      </button>
    </>
  );
};

const data = {

  navLinks: [
    {
      title: "Product",
      handle: "/products",
      subLinks: [
        {
          title: "Cloud Video Surveillance",
          handle: "/cloud-video-surveillance"
        },
        {
          title: "Cloud AI and Analytics",
          handle: "/cloud-ai-and-analytics"
        },
        {
          title: "Premium Camera Support",
          handle: "/premium-camera-support"
        },
        {
          title: "Supported Cameras Database",
          handle: "/supported-cameras-database"
        }
      ]
    },
    {
      title: "Solutions",
      handle: "/solutions"
    },
    {
      title: "Resources",
      handle: "/resources"
    },
    {
      title: "About Us",
      handle: "/about-us"
    },
    // {
    //   title: "Blog",
    //   handle: "#"
    // },
    {
      title: "Contact Us",
      handle: "/contact-us"
    }
  ]
}

const footerLinks = [
  {
    title: "Product",
    subLinks: [
      {
        link: "Cloud Video Surveillance",
        handle: "/cloud-video-surveillance"
      },
      {
        link: "Cloud AI and Analytics",
        handle: "/cloud-ai-and-analytics"
      },
      {
        link: "Premium Camera Support",
        handle: "/premium-camera-support"
      },
      {
        link: "Supported Cameras Database",
        handle: "/supported-cameras-database"
      }
    ]
  },
  // {
  //   title: "Solutions",
  //   subLinks: [
  //     {
  //       link: "Overview",
  //       handle: "#"
  //     },
  //     {
  //       link: "Restaurants & Retailers",
  //       handle: "#"
  //     },
  //     {
  //       link: "Multi-location Enterprises",
  //       handle: "#"
  //     },
  //     {
  //       link: "NVR Replacement",
  //       handle: "#"
  //     },
  //     {
  //       link: "License Plate Recognition (LPR)",
  //       handle: "#"
  //     },
  //     {
  //       link: "HOA & Neighbourhood Security",
  //       handle: "#"
  //     },
  //     {
  //       link: "DIY Solution for Home",
  //       handle: "#"
  //     }
  //   ]
  // },
  {
    title: "Quick Links",
    subLinks: [
      {
        link: "About Us",
        handle: "/about-us"
      },
      {
        link: "Resources",
        handle: "/resources"
      },
      {
        link: "Solutions",
        handle: "/solutions"
      },
      {
        link: "FAQs",
        handle: "/faqs"
      }
    ]
  },
  {
    title: "Company Info",
    subLinks: [
      {
        link: "Contact Us",
        handle: "/contact-us"
      },
      {
        link: "Privacy Policy",
        handle: "/privacy-policy"
      },
      {
        link: "Terms & Conditions",
        handle: "/terms-and-conditions"
      },
      {
        link: "Sitemap",
        handle: "/sitemap"
      }
    ]
  },
];

export default Layout;
