import React from 'react'
import Layout from '../components/shared/layout'
import ImageIcons from '../components/imageComponent/ImageIcons';
import { windowScroll } from '../components/utils/windowScroll';
import { Link } from 'react-router-dom';
import Priching from '../components/home/priching';
import InnerPageBanner from '../components/shared/innerPageBanner';
import AboutSection from '../components/home/aboutSection';

const Products = () => {
    return (
        <Layout>
            <InnerPageBanner title='Products' innerPageBanner={ImageIcons.contactBanner} />       
            <section>
                <div className='container'>
                    <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-[30px]'>
                        {servicesData.map((item, i) => (
                            <div key={i} className='grid sm:grid-cols-2 grid-cols-1 bg-[#fff] rounded-[10px] overflow-hidden shadow-[0_0_20px_3px_rgba(0,0,0,0.2)]'>
                                <div className='min-h-[320px]'>
                                    <img className='object-center object-cover w-full h-full' src={item.servicesImg} alt='' />
                                </div>
                                <div className='md:p-[30px] p-[25px]'>
                                    <h4 className='mb-[10px]'>{item.title}</h4>
                                    <p className='text-[15px]'>{item.text}</p>
                                    <div>
                                    <Link className="inline-flex text-[16px] bg-[#2984bd] hover:bg-[#ef7f1a] text-[#fff] rounded-[10px] md:px-[24px] max-md:px-[20px] md:py-[12px] max-md:py-[10px]" to={item.readMore} onClick={windowScroll}>
                                        Learn More
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <AboutSection />
            <Priching />
        </Layout>
    )
}

const servicesData = [
    {
        title: "Cloud Video Surveillance",
        text: "Discover the advantages of cloud-based video surveillance. Access your footage anytime, anywhere, with scalable solutions that fit your needs.",
        servicesImg: ImageIcons.servicesImg1,
        readMore: '/cloud-video-surveillance',
    },
    {
        title: "Cloud AI and Analytics",
        text: "Harness the power of artificial intelligence and advanced analytics to transform your security systems. Our cloud-based AI solutions provide real-time insights, automated monitoring, and enhanced security capabilities.",
        servicesImg: ImageIcons.servicesImg2,
        readMore: '/cloud-ai-and-analytics',
    },
    {
        title: "Premium Camera Support",
        text: "Experience the highest level of security and performance with our premium camera support. Our advanced cameras, combined with state-of-the-art cloud technology, offer unparalleled surveillance capabilities.",
        servicesImg: ImageIcons.servicesImg3,
        readMore: '/premium-camera-support',
    },
    {
        title: "Supported Cameras Database",
        text: "Our cloud video surveillance system supports a wide range of premium cameras to ensure optimal security and performance. Below is a list of supported cameras, including their key features and specifications.",
        servicesImg: ImageIcons.servicesImg4,
        readMore: '/supported-cameras-database',
    },
]

export default Products