import React, { useRef } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import ImageIcons from '../imageComponent/ImageIcons';

const Services = () => {
    const sliderRef = useRef(null);

    let settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
               }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            
          ]
    };

    return (
        <>
            <section className='services '>
                <div className='container'>
                    <div className='mb-[25px] text-left !max-w-full flex justify-between items-center'>
                        <h2 className='mb-0'>Services </h2>
                        <div className='flex'>
                            <span className='sm:h-[40px] sm:w-[40px] h-[35px] w-[35px] bg-[#2984bd] hover:bg-[#ef7f1a] text-white cursor-pointer rounded-[50%] flex justify-center items-center sm:text-[20px] text-[19px]' onClick={() => sliderRef.current.slickPrev()}>
                                <IoIosArrowBack />
                            </span>
                            <span className='ml-[15px] sm:h-[40px] sm:w-[40px] h-[35px] w-[35px] bg-[#2984bd] hover:bg-[#ef7f1a] text-white cursor-pointer rounded-[50%] flex justify-center items-center sm:text-[20px] text-[19px]' onClick={() => sliderRef.current.slickNext()}>
                                <IoIosArrowForward />
                            </span>
                        </div>
                    </div>

                    <Slider ref={sliderRef} {...settings}>
                        {servicesData.map((item, i) => (
                            <div key={i} className='relative'>
                                <div className='bg-[#fff] rounded-[5px] group overflow-hidden'>
                                    <div className="">
                                        <div className='overflow-hidden h-[260px]'>
                                            <img className='group-hover:scale-[1.1] duration-500 w-full h-full object-center object-cover' src={item.servicesImg} alt='' />
                                        </div>

                                        <div className='px-[20px] py-[30px]'>
                                            <h5 className='mb-[10px]'>{item.title}</h5>
                                            <p className='mb-0'>{item.text}</p>
                                            <Link className='text-[#5ca1cd] hover:text-[#ef7f1a] flex items-center mt-[10px]' to={item.readMore}>Read More <FaArrowRightLong className='ml-[10px]' /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    )
}

const servicesData = [
    {
        title: "Installation Services",
        text: "Depending on the provider, the complexity of the setup, and the specific needs of the user.",
        servicesImg: ImageIcons.servicesImg1,
        readMore: '#',
    },
    {
        title: "Maintenance & Support",
        text: "Crucial to ensure the system remains functional, secure, and up-to-date.",
        servicesImg: ImageIcons.servicesImg2,
        readMore: '#',
    },
    {
        title: "Custom Solutions",
        text: "Meet with stakeholders to understand their security concerns, specific areas that require surveillance...",
        servicesImg: ImageIcons.servicesImg3,
        readMore: '#',
    },
    {
        title: "Circuit Cameras",
        text: "Often referred to as CCTV (Closed-Circuit Television) cameras, are used for surveillance and security purposes...",
        servicesImg: ImageIcons.servicesImg4,
        readMore: '#',
    },
    {
        title: "Wifi Camera Security",
        text: "Wi-Fi security cameras offer a convenient and flexible option for surveillance by using wireless...",
        servicesImg: ImageIcons.servicesImg5,
        readMore: '#',
    },

]

export default Services