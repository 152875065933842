import React from 'react'
import { IoMdCheckmark } from "react-icons/io";
import ImageIcons from '../imageComponent/ImageIcons';

const AboutSection = () => {
    return (
        <>
            <section className='p-0'>
                <div className=' '>
                    <div className='flex lg:flex-nowrap flex-wrap  '>
                        <div className='md:min-h-[500px] sm:min-h-[400px] min-h-[330px] relative lg:w-[45%] w-full'>
                            <img className='object-cover h-full w-full absolute' src={ImageIcons.cloudBasedCctv} alt='' />
                        </div>
                        <div className='lg:w-[55%] w-full xl:px-[50px] lg:px-[40px] md:px-[25px] px-[15px] lg:py-[70px] py-[50px] relative bg-top bg-cover bg-no-repeat after:content-[""] after:absolute after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[#fff] after:opacity-90' style={{ backgroundImage: `url(${ImageIcons.aboutSecBg})` }}>
                            <div className='relative z-10 lg:max-w-[700px] max-w-full'>
                                <h2 className='font-bold text-[#2984bd]'>Advantages and Features</h2>
                                <p className='mb-[10px]'>Cloud-based CCTV surveillance refers to a system where video footage from security cameras is stored and managed over the internet rather than on local servers or hard drives. This setup offers several advantages and features:</p>
                                <div className='grid sm:grid-cols-2  grid-cols-1 lg:gap-[20px] sm:gap-[15px] gap-[10px]'>
                                    <div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Remote Access</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Scalability</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Cost-Effective</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Enhanced Security</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Automatic Updates</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Real-Time Monitoring</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Video Storage</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Data Security</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Playback and Search</h5>
                                            </div>
                                        </div>
                                        <div class="mt-[25px] flex justify-center items-center group">
                                            <span class="flex justify-center items-center sm:h-[45px] sm:w-[45px] h-[40px] w-[40px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[40px] text-[#2984bd] group-hover:text-[#fff]">
                                                <IoMdCheckmark className='sm:text-[30px] text-[25px] -rotate-45' />
                                            </span>
                                            <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                <h5 class="mb-0 md:text-[20px] text-[18px]">Analytics and AI</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection