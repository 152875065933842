import React from 'react'
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { IoMdCheckmark } from "react-icons/io";
import { Link } from 'react-router-dom';
import { GiCctvCamera } from "react-icons/gi";
import { windowScroll } from "../components/utils/windowScroll";
import GetStarted from '../components/home/getStarted';

const SolutionsPage = () => {
  return (
    <Layout>
      <InnerPageBanner title='Solutions' innerPageBanner={ImageIcons.contactBanner} />
      <>
        <section className='bg-[#f1f4f5]'>
          <div className='container !max-w-[1200px]'>
            <div className='grid md:grid-cols-2  grid-cols-1 lg:gap-[5%] gap-[25px]'>
              <div className='mb-0 mb-[20px]'>
                <h2 className='font-bold mb-[15px]'>Cloud-Based CCTV Surveillance</h2>
                <p>Our cloud-based CCTV systems deliver secure, scalable, and flexible surveillance options. With remote access to live and recorded footage, you can monitor your property anytime, anywhere. Key features include:</p>
                <div class="flex justify-center mb-[20px] group">
                  <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                  </span>
                  <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                    <h5 class="mb-[6px] font-bold">Secure Cloud Storage</h5>
                    <p class="mb-[0]">Store video footage in the cloud for easy access and retrieval, eliminating the need for on-site storage devices.</p>
                  </div>
                </div>
                <div class="flex justify-center mb-[20px] group">
                  <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                  </span>
                  <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                    <h5 class="mb-[6px] font-bold">Scalability</h5>
                    <p class="mb-[0]">Easily expand your surveillance system by adding more cameras and features as your needs grow.</p>
                  </div>
                </div>
                <div class="flex justify-center group">
                  <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                  </span>
                  <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                    <h5 class="mb-[6px] font-bold">Real-Time Alerts</h5>
                    <p class="mb-[0]">Receive instant notifications of unusual activity, allowing for quick responses to potential threats.</p>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-2'>
                <div>
                  <img className='object-cover h-full w-full' src={ImageIcons.cam1} alt='' />
                </div>
                <div>
                  <img className='object-cover h-full w-full' src={ImageIcons.cam2} alt='' />
                </div>
                <div>
                  <img className='object-cover h-full w-full' src={ImageIcons.cam3} alt='' />
                </div>
                <div>
                  <img className='object-cover h-full w-full' src={ImageIcons.cam4} alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative bg-[#fff]">
            <div className='container'>
                <div className='heading_title'>
                    <div className='mb-[10px]'>
                        <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                            <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
                        </span>
                    </div>
                    <h2>Total Security Solutions</h2>
                </div>
                <div className='grid md:grid-cols-3 lg:gap-[40px]'>
                    <div className='relative max-md:order-1'>
                        <div className="flex gap-[15px] md:mb-[30px] max-md:mb-[20px] md:mt-[50px]">
                            <div className="md:text-right max-md:order-2">
                                <h4 className="mb-[10px] text-[#000] font-semibold">Leading by innovation!</h4>
                                <p className='text-[15px] text-[#666] mb-[0px]'>Leading through innovation to redefine security solutions for a safer tomorrow!</p>
                            </div>
                            <div className='max-md:order-1'>
                                <div className='mt-[6px] w-[20px] h-[20px] rounded-full bg-[#2984bd] before:lg:content-[""] before:lg:absolute before:lg:right-[-38%] before:lg:w-[38%] before:lg:h-[1px] before:lg:mt-[10px] before:lg:border-b before:lg:border-[#2984bd]'></div>
                            </div>
                        </div>
                        <div className="flex gap-[15px] md:mb-[30px] max-md:mb-[20px] md:mt-[50px]">
                            <div className="md:text-right max-md:order-2">
                                <h4 className="mb-[10px] text-[#000] font-semibold">High inbuild speaker</h4>
                                <p className='text-[15px] text-[#666] mb-[0px]'>High in-built speaker for clear two-way audio communication and enhanced security interaction.</p>
                            </div>
                            <div className='max-md:order-1'>
                                <div className='mt-[6px] w-[20px] h-[20px] rounded-full bg-[#2984bd] before:lg:content-[""] before:lg:absolute before:lg:right-[-34%] before:lg:w-[34%] before:lg:h-[1px] before:lg:mt-[10px] before:lg:border-b before:lg:border-[#2984bd]'></div>
                            </div>
                        </div>
                        <div className="flex gap-[15px] md:mb-[30px] max-md:mb-[20px] md:mt-[20px]">
                            <div className="md:text-right max-md:order-2">
                                <h4 className="mb-[10px] text-[#000] font-semibold">Microphone</h4>
                                <p className='text-[15px] text-[#666] mb-[0px]'>Integrated microphone for crystal-clear audio capture and enhanced surveillance capabilities.</p>
                            </div>
                            <div className='max-md:order-1'>
                                <div className='mt-[6px] w-[20px] h-[20px] rounded-full bg-[#2984bd] before:lg:content-[""] before:lg:absolute before:lg:right-[-38%] before:lg:w-[38%] before:lg:h-[1px] before:lg:mt-[10px] before:lg:border-b before:lg:border-[#2984bd]'></div>
                            </div>
                        </div>
                    </div>
                    <div className='max-md:order-3'>
                        <div>
                            <img src={ImageIcons.survilencecamera} className="w-full max-w-[280px] mx-auto" alt="survilence camera"/>
                        </div>
                    </div>
                    <div className='relative max-md:order-2'>
                        <div className="flex gap-[15px] md:mb-[30px] max-md:mb-[20px] md:mt-[90px]">
                            <div>
                                <div className='mt-[6px] w-[20px] h-[20px] rounded-full bg-[#2984bd] before:lg:content-[""] before:lg:absolute before:lg:left-[-38%] before:lg:w-[38%] before:lg:h-[1px] before:lg:mt-[10px] before:lg:border-b before:lg:border-[#2984bd]'></div>
                            </div>
                            <div className="md:text-left">
                                <h4 className="mb-[10px] text-[#000] font-semibold">Always reliable installation</h4>
                                <p className='text-[15px] text-[#666] mb-[0px]'>Always reliable installation for seamless and effective security setup.</p>
                            </div>
                        </div>
                        <div className="flex gap-[15px] md:mb-[30px] max-md:mb-[20px] md:mt-[50px]">
                            <div>
                                <div className='mt-[6px] w-[20px] h-[20px] rounded-full bg-[#2984bd] before:lg:content-[""] before:lg:absolute before:lg:left-[-38%] before:lg:w-[38%] before:lg:h-[1px] before:lg:mt-[10px] before:lg:border-b before:lg:border-[#2984bd]'></div>
                            </div>
                            <div className="md:text-left">
                                <h4 className="mb-[10px] text-[#000] font-semibold">Hardware never fails</h4>
                                <p className='text-[15px] text-[#666] mb-[0px]'>Hardware you can trust—engineered for durability and consistent performance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        <GetStarted />
        <section>
          <div className='container !max-w-[1200px]'>
            <div className='heading_title'>
              <div className='mb-[10px]'>
                <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                  <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
                </span>
              </div>
              <h2>Take your ordinary cameras to the next </h2>
            </div>
            <div className=''>
              {data.map((item, i) => (
                <div key={i} className='flex md:flex-nowrap flex-wrap even:flex-row-reverse flex-wrap-reverse	items-center mb-[40px] last:mb-0 bg-[#f1f4f5] rounded-[10px]' >
                  <div className='md:w-[50%] w-full'>
                    <img className='lg:h-auto sm:h-[380px] h-[300px] object-cover m-auto' src={item.imageMob} alt='' />
                  </div>
                  <div className='md:w-[50%] w-full'>
                    <div className='xl:px-[60px] lg:px-[50px] md:px-[40px] sm:px-[30px] px-[20px] py-[30px] max-w-[575px] m-auto md:text-left text-center md:mt-0 mt-[15px]'>
                      <h3 className='font-bold mb-[15px]'>{item.title}</h3>
                      <h5 className='font-bold mb-[5px]'>{item.subtitleone}</h5>
                      <h5 className='font-bold mb-[15px]'>{item.subtitletwo}</h5>
                      <p>{item.desc}</p>
                      <div>
                        <Link to={item.link} onClick={windowScroll} className='md:inline-flex text-[16px] md:bg-[#2984bd] md:hover:bg-[#ef7f1a] md:text-[#fff] rounded-[10px] md:px-[24px] md:px-[20px] md:py-[12px] max-md:flex max-md:justify-center max-md:text-[#2984bd] max-md:hover:text-[#ef7f1a]'>{item.linkText}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    </Layout>
  )
}
const data = [
  {
    title: "Restaurants & Retailers",
    subtitleone: "Is your CCTV on its last legs?",
    subtitletwo: "Move to the cloud and protect your business.",
    desc: "Looking for a modern approach to video surveillance? Business owners can easily, affordably and quickly make the upgrade to a state-of-the-art hardware-free cloud video surveillance solution.",
    imageMob: ImageIcons.mobileImg1,
    link: "/contact-us",
    linkText: "Learn More"
  },
  {
    title: "Multi-Location Enterprises",
    subtitleone: "Control costs in your enterprise.",
    subtitletwo: "Manage video surveillance 100% remotely.",
    desc: "For enterprises with large numbers of locations, costs can add up quickly for surveillance management. Camcloud’s unique hardware-free approach delivers the industry’s lowest total cost of ownership.",
    imageMob: ImageIcons.mobileImg1,
    link: "/contact-us",
    linkText: "Learn More"
  },
  {
    title: "Reseller Solutions",
    subtitleone: "Build your business. Become a Camcloud Reseller.",
    subtitletwo: " ",
    desc: "Enhance your solutions and earn recurring revenue. Build a profitable recurring revenue stream on your camera installations with cloud video surveillance, all under your own brand. Camcloud’s partner-first solutions deliver easy-to-use, hardware-free cloud video surveillance.",
    imageMob: ImageIcons.mobileImg1,
    link: "/contact-us",
    linkText: "Learn More"
  },
];
export default SolutionsPage
