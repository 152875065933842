import React from 'react'
import Layout from '../components/shared/layout'
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import SideBar from '../components/shared/SideBar';

const SupportedCamerasDatabase = () => {
    return (
        <Layout>
            <InnerPageBanner title='Supported Cameras Database' innerPageBanner={ImageIcons.contactBanner} />
            <section>
                <div className='container'>
                    <div className='single-services-info flex gap-10 max-md:flex-col'>
                        <div className='md:w-3/4 relative mb-5 max-md:order-1'>
                            <div>
                                <h2>Supported Cameras Database</h2>
                                <p>At MyCloudCam, we understand the importance of compatibility and flexibility when it comes to your security needs. Our Supported Cameras Database ensures that you can integrate a wide range of cameras with our cloud platform, providing you with the best options for your specific requirements. Discover the seamless integration and extensive compatibility that make MyCloudCam the ideal choice for your surveillance system.</p>
                                <h4>Key Features</h4>
                                <ul className='list-decimal pl-[20px] mb-[30px]'>
                                    <li className='mb-[10px]'><b>Extensive Compatibility</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Wide Range of Brands:</b> Support for major camera brands including Hikvision, Dahua, Axis, Sony, and many more.</li>
                                            <li><b>Various Models:</b> Compatibility with different models, from basic security cameras to advanced IP cameras.</li>
                                        </ul>
                                    </li>
                                    <li className='mb-[10px]'><b>Easy Integration</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Plug and Play:</b> Simple setup process with auto-detection and configuration for supported cameras.</li>
                                            <li><b>Unified Interface:</b> Manage all your cameras through a single, user-friendly interface.</li>
                                        </ul>
                                    </li>
                                    <li className='mb-[10px]'><b>Regular Updates</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Continuous Expansion:</b> Our database is regularly updated to include new camera models and brands.</li>
                                            <li><b>Firmware Compatibility:</b> Ensuring support for the latest firmware versions to maintain optimal performance.</li>
                                        </ul>
                                    </li>
                                    <li className='mb-[0px]'><b>Customizable Settings</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Advanced Configuration:</b> Tailor camera settings to fit your specific needs, including resolution, frame rate, and motion detection zones.</li>
                                            <li><b>Flexible Storage Options:</b> Choose from various cloud storage plans to suit your storage requirements.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <h4>How It Works</h4>
                                <ul className='mb-[30px]'>
                                    <li className='mb-[10px]'><b>Check Compatibility:</b> Visit our Supported Cameras Database on the MyCloudCam website to check if your camera model is supported.</li>
                                    <li className='mb-[10px]'><b>Connect Your Camera:</b> Follow the simple instructions to connect your camera to the MyCloudCam platform using our auto-detection feature.</li>
                                    <li className='mb-[10px]'><b>Configure Settings:</b> Customize your camera settings through the MyCloudCam app or web interface to optimize performance and storage.</li>
                                    <li className='mb-[10px]'><b>Start Monitoring:</b> Begin monitoring your space with seamless integration and enjoy the comprehensive features of MyCloudCam.</li>
                                </ul>
                                <h4>Why Choose MyCloudCam for Your Supported Cameras?</h4>
                                <ul className='mb-[30px]'>
                                    <li className='mb-[10px]'><b>Flexibility:</b> Wide range of supported cameras allows you to choose the best options for your needs.</li>
                                    <li className='mb-[10px]'><b>Ease of Use:</b> Simple integration and user-friendly interface make managing your cameras effortless.</li>
                                    <li className='mb-[10px]'><b>Up-to-Date:</b> Regular updates ensure compatibility with the latest camera models and firmware.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='md:w-1/4 relative mb-5 max-md:order-2 md:border-l-[1px] md:border-solid md:border-inherit'>
                            <SideBar />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default SupportedCamerasDatabase
