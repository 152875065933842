import React from 'react'
import Layout from '../components/shared/layout'
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import SideBar from '../components/shared/SideBar';

const CloudAIandAnalytics = () => {
    return (
        <Layout>
            <InnerPageBanner title='Cloud AI and Analytics' innerPageBanner={ImageIcons.contactBanner} />
            <section>
                <div className='container'>
                    <div className='single-services-info flex gap-10 max-md:flex-col'>
                        <div className='md:w-3/4 relative mb-5 max-md:order-1'>
                            <div>
                                <h2>Cloud AI and Analytics</h2>
                                <p>Step into the future of surveillance with MyCloudCam advanced Cloud AI and Analytics. Leveraging the power of artificial intelligence and cloud computing, our system offers intelligent monitoring and insightful analytics to keep you informed and secure. Transform the way you manage security with real-time data and smart analysis.</p>
                                <h4>Key Features</h4>
                                <ul className='list-decimal pl-[20px] mb-[30px]'>
                                    <li className='mb-[10px]'><b>Artificial Intelligence</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Smart Detection:</b> AI-powered algorithms distinguish between different types of motion, reducing false alarms and ensuring you only get notified for genuine threats.</li>
                                            <li><b>Facial Recognition:</b> Identify familiar faces and recognize intruders with our cutting-edge facial recognition technology.</li>
                                        </ul>
                                    </li>
                                    <li className='mb-[10px]'><b>Advanced Analytics</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Behavior Analysis:</b> Track and analyze patterns of movement to identify unusual activity and potential security risks.</li>
                                            <li><b>Heat Maps:</b> Visualize high-traffic areas within your monitored space to optimize security and understand movement patterns.</li>
                                        </ul>
                                    </li>
                                    <li className='mb-[10px]'><b>Real-Time Insights</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Instant Alerts:</b> Receive detailed notifications with relevant information such as type of motion detected and time of occurrence.</li>
                                            <li><b>Live Reporting:</b> Access up-to-the-minute reports and summaries directly from your MyCloudCam dashboard.</li>
                                        </ul>
                                    </li>
                                    <li className='mb-[0px]'><b>Historical Data Analysis</b>
                                        <ul className='list-disc mt-[5px] pl-[20px]'>
                                            <li className='mb-[5px]'><b>Data Retention:</b> Store and review past footage and analytics to understand trends and make informed decisions.</li>
                                            <li><b>Comprehensive Reports:</b> Generate in-depth reports on historical data to enhance security planning and response strategies.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <h4>How It Works</h4>
                                <ul className='mb-[30px]'>
                                    <li className='mb-[10px]'><b>Data Collection:</b> Cameras continuously capture video footage, which is securely transmitted to the cloud for analysis.</li>
                                    <li className='mb-[10px]'><b>AI Processing:</b> The footage is processed in real-time by AI algorithms that identify and categorize different types of activity.</li>
                                    <li className='mb-[10px]'><b>Analytics Generation:</b> The processed data is analyzed to provide actionable insights and generate detailed reports.</li>
                                    <li className='mb-[10px]'><b>User Interaction:</b> Access all insights, alerts, and reports through the MyCloudCam app or web interface.</li>
                                </ul>
                                <h4>Benefits of Cloud AI and Analytics</h4>
                                <ul className='mb-[30px]'>
                                    <li className='mb-[10px]'><b>Enhanced Security:</b> Intelligent detection and real-time insights ensure a proactive approach to security.</li>
                                    <li className='mb-[10px]'><b>Time Efficiency:</b> Reduce the time spent reviewing footage with automated analysis and relevant alerts.</li>
                                    <li className='mb-[10px]'><b>Informed Decisions:</b> Use data-driven insights to optimize your security measures and response strategies.</li>
                                    <li className='mb-[10px]'><b>Scalability:</b> Easily scale your surveillance capabilities without the need for additional hardware or complex installations.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='md:w-1/4 relative mb-5 max-md:order-2 md:border-l-[1px] md:border-solid md:border-inherit'>
                            <SideBar />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default CloudAIandAnalytics
