import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { MdConnectWithoutContact } from "react-icons/md";
import ImageIcons from '../imageComponent/ImageIcons';

const SideBar = () => {

    // Curren link
    const pathname = useLocation().pathname;
  
    return (
        <>
            <div className='single-service-left-sidebar md:pl-[30px]'>
                <div className='box-shodow-info xl:px-[30px] xl:py-[30px] max-xl:px-[20px] max-xl:py-[20px] rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                    <div className='title-sidebar mb-5'>
                        <h4 className='mb-[15px]'>Our Products</h4>
                        <hr className="green-hr-line border-0 border-b-[1px] border-solid border-inherit"></hr>
                    </div>
                    <div className='services-links-info'>
                        <ul>
                            <li className="mb-[4px]">
                                <Link className={`${pathname === `/cloud-video-surveillance` && "!text-[#fff] bg-[#2984bd]"} flex px-4 py-2 hover:bg-[#2984bd] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]`} to="/cloud-video-surveillance">Cloud Video Surveillance</Link>
                            </li>
                            <li className="mb-[4px]">
                                <Link className={`${pathname === `/cloud-ai-and-analytics` && "!text-[#fff] bg-[#2984bd]"} flex px-4 py-2 hover:bg-[#2984bd] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]`} to="/cloud-ai-and-analytics">Cloud AI and Analytics</Link>
                            </li>
                            <li className="mb-[4px]">
                                <Link className={`${pathname === `/premium-camera-support` && "!text-[#fff] bg-[#2984bd]"} flex px-4 py-2 hover:bg-[#2984bd] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]`} to="/premium-camera-support">Premium Camera Support</Link>
                            </li>
                            <li>
                                <Link className={`${pathname === `/supported-cameras-database` && "!text-[#fff] bg-[#2984bd]"} flex px-4 py-2 hover:bg-[#2984bd] hover:text-[#fff] rounded-md border-b border-solid border-[#ddd]`} to="/supported-cameras-database">Supported Cameras Database</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='box-shodow-info xl:px-[30px] xl:py-[30px] max-xl:px-[20px] max-xl:py-[20px] rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                    <div className='title-sidebar mb-5'>
                        <h4 className='mb-[15px]'>Download</h4>
                        <hr className="green-hr-line border-0 border-b-[1px] border-solid border-inherit"></hr>
                    </div>
                    <div className='flex flex-row'>
                        <img className='w-full h-full max-w-[40px] mr-2' src={ImageIcons.pdficon} alt='Pdf' />
                        <div className="download-txt-info">
                            <h5 className="text-[20px] mb-[0px]">Download our Brochure</h5>
                            <p>
                                <Link className="flex items-center gap-2 cursor-pointer text-[#2984bd] font-medium" download="myEV_Point" to="#" target="_blank">Download</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='box-shodow-info rounded-md shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] xl:mb-10 max-xl:mb-5'>
                    <div className='contact-img-gril'>
                        <img className='w-full' src={ImageIcons.contactpic} alt='contact pic' />
                    </div>
                    <div className='relative text-center xl:px-10 xl:pb-10 max-xl:px-5 max-xl:pb-5 mt-[-80px] bg-[linear-gradient(-6deg,_#2984bd_74.9%,#fff0_75.2%)]'>
                        <div className='bg-[#fff] w-[74px] h-[74px] flex items-center justify-center rounded-full mx-auto'>
                            <MdConnectWithoutContact size={40} className="text-[#2984bd]" />
                        </div>
                        <h4 className="mb-3 mt-4 text-white font-medium">Do you need any help?</h4>
                        <p className="mb-0 text-white font-medium cursor-pointer">+91 90561-11675</p>
                        <p className="mb-0 text-white font-medium cursor-pointer">cammycloud67@gmail.com</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBar
