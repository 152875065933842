import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IoMdMenu } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import ImageIcons from '../imageComponent/ImageIcons';

const DrawerMenu = ({ navLinks }) => {

    // Curren link
    const pathname = useLocation().pathname;

    const navigate = useNavigate();
    const [toggleDropdown, setToggleDropdown] = React.useState(false);
    
    const [state, setState] = React.useState({
        left: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

      const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
                <List className='!py-0'>
                    <div className='px-[16px] py-[20px] bg-[#2984bd]'>
                        <Link to="/" >
                            <img className='h-[50px] ' src={ImageIcons.logo} alt='' />
                        </Link>
                    </div>
                    {navLinks.map((item, index) => (
                        <>
                        <ListItem key={index} disablePadding className={`${pathname === item.handle && "!text-[#2984bd]"} border-solid border-b border-[#ddd] last:border-none`}>
                            <ListItemButton onClick={() => navigate(item.handle)}>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                            {item.subLinks && (
                                <ListItemButton className='!flex !justify-end !absolute right-0' onClick={() => setToggleDropdown(prev => !prev)}>{toggleDropdown ? <IoIosArrowUp /> : <IoIosArrowDown />}</ListItemButton>
                            )}
                        </ListItem>
                        {item.subLinks && (
                            <Collapse in={toggleDropdown} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.subLinks.map((item, i) => (
                                        <ListItem key={i} disablePadding className={`${pathname === item.handle && "!text-[#fff] bg-[#ef7f1a]"} bg-[#2984bd] text-white border-solid border-b border-[#ffffff29] last:border-none `}>
                                            <ListItemButton 
                                                onClick={() => {
                                                    navigate(item.handle)
                                                    toggleDrawer('left', false);
                                                }}
                                            >
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                        </>
                    ))}
                </List>
                {/* <List>
                     
                    <ListItem>
                        <ListItemButton className='!duration-500 !bg-[#2984bd] hover:!bg-[#2560c0] !text-white !text-center !text-[18px] !font-bold h-[50px]'>
                            <ListItemText primary="Login" />
                        </ListItemButton>
                    </ListItem>
                </List> */}
            </Box>
        </Box>
      );
  return (
    <>
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><IoMdMenu className='text-[36px] text-white' /></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
    </>
  )
}

export default DrawerMenu