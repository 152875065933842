import React from 'react';
import teampic1 from "../../assets/images/president.webp";
import teampic3 from "../../assets/images/cto.webp";
import teampic2 from "../../assets/images/ceo.webp";
import { GiCctvCamera } from 'react-icons/gi';

const LeadershipTeam = () => {
  return (
    <>
        <section className="relative bg-[#fff]">
            <div className='container relative'>
              <div className='heading_title'>
                <div className='mb-[10px]'>
                  <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                    <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
                  </span>
                </div>
                <h2>Founders of The MyCloudCam</h2>
              </div>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 gap-[20px]'>
                    <div className='md:mt-[90px] max-md:mt-[75px]'>
                      <div className='bg-white text-center p-[15px] rounded-[10px] shadow-[0_0_1.25rem_rgba(108,_118,_134,_0.1)] duration-500 hover:bg-[#2984bd] group'>
                        <div className='md:p-[25px] max-md:p-[15px] border-dashed border-[1px] border-[#ddd]'>
                          <div className=' md:mt-[-100px] max-md:mt-[-78px]'>
                              <img src={teampic1} alt={data.team[0].fullName} className='mx-auto object-cover object-top rounded-full md:w-[150px] max-md:w-[110px] md:h-[150px] max-md:h-[110px] shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)]'/>
                          </div>
                          <div>
                              <h3 className='md:text-[24px] max-md:text-[20px] md:mt-[20px] max-md:mt-[10px] md:mb-[10px] max-md:mb-[5px] group-hover:text-[#ffffff]'>{data.team[0].fullName}</h3>
                              <h4 className="md:text-[18px] max-md:text-[16px] text-[#888888] my-0 group-hover:text-[#ffffff]">{data.team[0].designation}</h4>
                              <p class="md:text-[15px] max-md:text-[14px] md:mt-[20px] max-md:mt-[10px] mb-[0px] group-hover:text-[#ffffff]">{data.team[0].description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='md:mt-[90px] max-md:mt-[75px]'>
                      <div className='bg-white text-center p-[15px] rounded-[10px] shadow-[0_0_1.25rem_rgba(108,_118,_134,_0.1)] duration-500 hover:bg-[#2984bd] group'>
                        <div className='md:p-[25px] max-md:p-[15px] border-dashed border-[1px] border-[#ddd]'>
                          <div className=' md:mt-[-100px] max-md:mt-[-78px]'>
                              <img src={teampic2} alt={data.team[1].fullName} className='mx-auto object-cover object-top rounded-full md:w-[150px] max-md:w-[110px] md:h-[150px] max-md:h-[110px] shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)]'/>
                          </div>
                          <div>
                              <h3 className='md:text-[24px] max-md:text-[20px] md:mt-[20px] max-md:mt-[10px] md:mb-[10px] max-md:mb-[5px] group-hover:text-[#ffffff]'>{data.team[1].fullName}</h3>
                              <h4 className="md:text-[18px] max-md:text-[16px] text-[#888888] my-0 group-hover:text-[#ffffff]">{data.team[1].designation}</h4>
                              <p class="md:text-[15px] max-md:text-[14px] md:mt-[20px] max-md:mt-[10px] mb-[0px] group-hover:text-[#ffffff]">{data.team[1].description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='md:mt-[90px] max-md:mt-[75px]'>
                      <div className='bg-white text-center p-[15px] rounded-[10px] shadow-[0_0_1.25rem_rgba(108,_118,_134,_0.1)] duration-500 hover:bg-[#2984bd] group'>
                        <div className='md:p-[25px] max-md:p-[15px] border-dashed border-[1px] border-[#ddd]'>
                          <div className=' md:mt-[-100px] max-md:mt-[-78px]'>
                              <img src={teampic3} alt={data.team[2].fullName} className='mx-auto object-cover object-top rounded-full md:w-[150px] max-md:w-[110px] md:h-[150px] max-md:h-[110px] shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)]'/>
                          </div>
                          <div>
                              <h3 className='md:text-[24px] max-md:text-[20px] md:mt-[20px] max-md:mt-[10px] md:mb-[10px] max-md:mb-[5px] group-hover:text-[#ffffff]'>{data.team[2].fullName}</h3>
                              <h4 className="md:text-[18px] max-md:text-[16px] text-[#888888] my-0 group-hover:text-[#ffffff]">{data.team[2].designation}</h4>
                              <p class="md:text-[15px] max-md:text-[14px] md:mt-[20px] max-md:mt-[10px] mb-[0px] group-hover:text-[#ffffff]">{data.team[2].description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
const data = {
  title: "Leadership Team",
  description: "I am delighted to welcome you to Abacus. We are transforming the landscape of cloud computing and broadband services in our state. Continuously striving to expand our reach, enhance capabilities, and broaden service offerings, Abacus is at the forefront of revolutionizing cloud and networking in Punjab through its secure, intelligent, and efficient infrastructure—a consistent effort over the past few months.<br/><br/> Our innovative mindset and forward-thinking approach set us apart. This distinctiveness is why we consistently earn trust and why numerous clients return to us. We have a clear vision of our objective—providing secure dataspace and high-speed internet access with absolute reliability. Guided by five core values—lead, grow, deliver, sustain, and protect—we steer our business. <br/><br/> I take pride in the work we accomplish and personally assure you that we will uphold our commitments, ensuring safety and sustainability in all that we deliver.",
  team: [
      {
          fullName: "Ranjeet Singh Sandhu",
          designation: "President Emeritus",
          description: "I am devoted to transforming the landscape of cloud computing in the state. I want The Abacus to be at the forefront of revolutionising cloud and networking via its secure, intelligent, and efficient infrastructure."
      },
      {
          fullName: "Navjot Singh Sandhu",
          designation: "CEO/MD",
          description: "I have always wanted to stay updated with the latest trends in the industry. I do not only want to build a great product but also an ecosystem that can garner value towards society"
      },
      {
          fullName: "DR. Pawanpreet Dhaliwal",
          designation: "CTO/Director",
          description: "Being a passionate tech entrepreneur, I have always believed in turning ideas into impactful realities. As an expert in technology innovation and strategic leadership, I thrive in dynamic environments and challenges."
      },
  ]
}
export default LeadershipTeam
