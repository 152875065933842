import React from 'react'
import { Link } from 'react-router-dom';
import { GiCctvCamera } from "react-icons/gi";
import ImageIcons from '../imageComponent/ImageIcons';
import { windowScroll } from "../utils/windowScroll";

const EnhancedCamera = () => {
  return (
    <section>
      <div className='container !max-w-[1200px]'>
        <div className='heading_title'>
          <div className='mb-[10px]'>
            <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
              <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
            </span>
          </div>
          <h2>Take your ordinary cameras to the next </h2>
        </div>
        <div className=''>
          {data.map((item, i) => (
            <div key={i} className='flex md:flex-nowrap flex-wrap even:flex-row-reverse flex-wrap-reverse	items-center mb-[30px] last:mb-0 bg-[#f1f4f5] rounded-[10px]' >
              <div className='md:w-[50%] w-full'>
                <img className='lg:h-auto sm:h-[380px] h-[300px] object-cover m-auto' src={item.imageMob} alt='' />
              </div>
              <div className='md:w-[50%] w-full'>
                <div className='xl:px-[60px] lg:px-[50px] md:px-[40px] sm:px-[30px] px-[20px] py-[30px] max-md:pb-[0px] max-w-[575px] m-auto md:text-left text-center md:mt-0 mt-[15px]'>
                  <h2 className='md:text-[34px] max-md:text-[26px] font-bold mb-[10px]'>{item.title}</h2>
                  <p className='max-md:mb-[10px] md:mb-[20px]'>{item.desc}</p>
                  <div>
                    <Link to={item.link} onClick={windowScroll} className='inline-flex text-[16px] md:bg-[#2984bd] md:hover:bg-[#ef7f1a] md:text-[#fff] rounded-[10px] md:px-[24px] md:px-[20px] md:py-[12px] max-md:flex max-md:justify-center max-md:text-[#2984bd] max-md:hover:text-[#ef7f1a]'>{item.linkText}</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='mt-[30px]'>
            <div className='flex md:flex-nowrap flex-wrap even:flex-row-reverse flex-wrap-reverse	items-center gap-[30px] mb-[40px] last:mb-0' >
              <div className='md:w-[50%] w-full bg-[#f1f4f5] rounded-[10px]'>
                <div className='xl:px-[60px] lg:px-[50px] md:px-[40px] sm:px-[30px] px-[20px] py-[30px] pb-[0px] max-w-[575px] m-auto md:text-left text-center md:mt-0 mt-[15px]'>
                  <h2 className='md:text-[34px] max-md:text-[26px] text-center font-bold mb-[10px]'>{datacontent.datasecone[0].title}</h2>
                  <p className='text-center mb-[10px]'>{datacontent.datasecone[0].desc}</p>
                  <Link to={datacontent.datasecone[0].link} className='flex justify-center text-[#2984bd] hover:text-[#ef7f1a] text-[16px]'>{datacontent.datasecone[0].linkText}</Link>
                  <div className='w-full'>
                    <img className='lg:h-auto sm:h-[380px] h-[300px] object-cover m-auto' src={datacontent.datasecone[0].imageMob} alt='' />
                  </div>
                </div>
              </div>
              <div className='md:w-[50%] w-full bg-[#f1f4f5] rounded-[10px]'>
                <div className='xl:px-[60px] lg:px-[50px] md:px-[40px] sm:px-[30px] px-[20px] py-[30px] pb-[0px] max-w-[575px] m-auto md:text-left text-center md:mt-0 mt-[15px]'>
                  <h2 className='md:text-[34px] max-md:text-[26px] text-center font-bold mb-[10px]'>{datacontent.datasecone[1].title}</h2>
                  <p className='text-center mb-[10px]'>{datacontent.datasecone[1].desc}</p>
                  <Link to={datacontent.datasecone[1].link} className='flex justify-center text-[#2984bd] hover:text-[#ef7f1a] text-[16px]'>{datacontent.datasecone[1].linkText}</Link>
                  <div className='w-full'>
                    <img className='lg:h-auto sm:h-[380px] h-[300px] object-cover m-auto' src={datacontent.datasecone[1].imageMob} alt='' />
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className='mt-[30px]'>
            <div className='flex md:flex-nowrap flex-wrap even:flex-row-reverse flex-wrap-reverse	items-center mb-[30px] last:mb-0 bg-[#f1f4f5] rounded-[10px]' >
              <div className='md:w-[50%] w-full'>
                <img className='lg:h-auto sm:h-[380px] h-[300px] object-cover m-auto' src={datacontent.datasecone[2].imageMob} alt='' />
              </div>
              <div className='md:w-[50%] w-full'>
                <div className='xl:px-[60px] lg:px-[50px] md:px-[40px] sm:px-[30px] px-[20px] py-[30px] max-md:pb-[0px] max-w-[575px] m-auto md:text-left text-center md:mt-0 mt-[15px]'>
                  <h2 className='md:text-[34px] max-md:text-[26px] font-bold mb-[10px]'>{datacontent.datasecone[2].title}</h2>
                  <p className='max-md:mb-[10px] md:mb-[20px]'>{datacontent.datasecone[2].desc}</p>
                  <div>
                    <Link to={datacontent.datasecone[2].link} onClick={windowScroll} className='inline-flex text-[16px] md:bg-[#2984bd] md:hover:bg-[#ef7f1a] md:text-[#fff] rounded-[10px] md:px-[24px] md:px-[20px] md:py-[12px] max-md:flex max-md:justify-center max-md:text-[#2984bd] max-md:hover:text-[#ef7f1a]'>{datacontent.datasecone[2].linkText}</Link>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}

const data = [
  {
    title: "Essentials plan",
    desc: "Creating an essentials plan for real time security involves establishing fundamental practices and tools to ensure continuous monitoring and protection of systems and data",
    imageMob: ImageIcons.mobileImg1,
    link: "/contact-us",
    linkText: "Learn More"
  },
  {
    title: "Cloud recording",
    desc: "Cloud recording involves capturing and storing data, such as video, audio, or other digital content, directly in a cloud storage system",
    imageMob: ImageIcons.cloudrecording,
    link: "/contact-us",
    linkText: "Learn More"
  },
];

const datacontent = {
  datasecone: [
      {
        title: "Broadcasting",
        desc: "Broadcasting refers to the distribution of audio and video content to a dispersed audience via any electronic mass communications medium.",
        imageMob: ImageIcons.mobileImg1,
        link: "/contact-us",
        linkText: "Learn More"
      },
      {
        title: "Real-time security",
        desc: "Real-time security involves the continuous monitoring and assessment of systems, networks, and data to detect and respond to threats as they occur.",
        imageMob: ImageIcons.mobileImg1,
        link: "/contact-us",
        linkText: "Learn More"
      },
      {
        title: "Timelapse",
        desc: "Timelapse photography is a technique where a series of photos are taken at regular intervals over a period of time and then compiled into a video that shows the sequence at a much faster speed.",
        imageMob: ImageIcons.mobileImg1,
        link: "/contact-us",
        linkText: "Learn More"
      },
  ]
}
export default EnhancedCamera