import React from 'react'
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
        <InnerPageBanner title='Privacy Policy' innerPageBanner={ImageIcons.contactBanner} />
            <section>
                <div className='container !max-w-[992px]'>
                    <div>
                        <h2 className='text-[34px] mb-[15px]'>Introduction</h2>
                        <p className='mb-[30px]'>Welcome to MyCloudCam privacy policy. We are committed to protecting your personal information and your right to privacy. This policy outlines how we collect, use, and protect your information when you use our cloud cam services and visit our website https://www.mycloudcam.in/.</p>
                    </div>
                    <div>
                        <h2 className='text-[34px] mb-[15px]'>Information Collection</h2>
                        <h4 className='mb-[15px]'>Types of Data Collected:</h4>
                        <ul className='list-disc pl-[24px] mb-[30px]'>
                          <li><span className='font-semibold'>Personal Data:</span> Name, email address, phone number, payment information.</li>
                          <li><span className='font-semibold'>Usage Data:</span> IP address, browser type, pages visited, time spent on pages, and other diagnostic data.</li>
                          <li><span className='font-semibold'>Video and Audio Data:</span> Recorded footage, live stream data, audio recordings from your cloud cam devices.</li>
                          <li><span className='font-semibold'>Device Data:</span> Information about your devices, including model, operating system, and unique device identifiers.</li>
                        </ul>
                        <h4 className='mb-[15px]'>Methods of Collection:</h4>
                        <ul className='list-disc pl-[24px] mb-[30px]'>
                          <li><span className='font-semibold'>Directly from You:</span> When you register for an account, purchase our products, or contact us directly.</li>
                          <li><span className='font-semibold'>Automatically:</span> Through cookies, web beacons, and similar tracking technologies when you use our website or services.</li>
                          <li><span className='font-semibold'>From Your Devices:</span> When you use our cloud cam devices, they automatically record and transmit data to our servers.</li>
                        </ul>
                        <h2 className='text-[34px] mb-[15px]'>Use of Information</h2>
                        <p className='text-[18px] font-semibold'>We use the collected data for various purposes:</p>
                        <ul className='list-disc pl-[24px] mb-[30px]'>
                          <li>To provide and maintain our services.</li>
                          <li>To notify you about changes to our services.</li>
                          <li>To allow you to participate in interactive features of our services.</li>
                          <li>To provide customer support.</li>
                          <li>To monitor the usage of our services.</li>
                          <li>To detect, prevent, and address technical issues.</li>
                          <li>To improve our products and services based on your feedback.</li>
                        </ul>
                        <h2 className='text-[34px] mb-[15px]'>Data Sharing and Disclosure</h2>
                        <p className='text-[18px] font-semibold'>We may share your information in the following situations:</p>
                        <ul className='list-disc pl-[24px] mb-[30px]'>
                          <li><span className='font-semibold'>With Service Providers:</span> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                          <li><span className='font-semibold'>For Legal Requirements:</span> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
                          <li><span className='font-semibold'>Business Transfers:</span> In connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                        </ul>
                        <h2 className='text-[34px] mb-[15px]'>Data Security</h2>
                        <p className='mb-[30px]'>We take the security of your data seriously and implement appropriate technical and organizational measures to protect your personal information. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
                        <h2 className='text-[34px] mb-[15px]'>User Rights</h2>
                        <p className='text-[18px] font-semibold'>You have the following rights regarding your data:</p>
                        <ul className='list-disc pl-[24px] mb-[30px]'>
                          <li><span className='font-semibold'>Access:</span> You have the right to access the personal data we hold about you.</li>
                          <li><span className='font-semibold'>Correction:</span> You have the right to correct any inaccuracies in your personal data.</li>
                          <li><span className='font-semibold'>Deletion:</span> You have the right to request the deletion of your personal data.</li>
                          <li><span className='font-semibold'>Data Portability:</span> You have the right to receive your personal data in a structured, commonly used, and machine-readable format.</li>
                          <li><span className='font-semibold'>Objection and Restriction:</span> You have the right to object to the processing of your personal data and to request the restriction of processing under certain circumstances.</li>
                        </ul>
                        <h2 className='text-[34px] mb-[15px]'>Cookies and Tracking Technologies</h2>
                        <p className='mb-[15px]'>We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.</p>
                        <p className='mb-[30px]'>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.</p>
                        <h2 className='text-[34px] mb-[15px]'>Third-Party Links</h2>
                        <p className='mb-[30px]'>Our services may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site.</p>
                    </div>
                </div>
            </section>
    </Layout>
  )
}

export default PrivacyPolicy
