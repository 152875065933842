import React from 'react'
import { Link } from 'react-router-dom';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';
import { IoMdCheckmark } from "react-icons/io";
import { windowScroll } from '../components/utils/windowScroll';

const Resources = () => {
  return (
    <Layout>
        <InnerPageBanner title='Resources' innerPageBanner={ImageIcons.contactBanner} />
        <section className='bg-[#dbdae0]'>
          <div className='container'>
            <div className='mb-[40px]'>
                <h2 className='font-bold mb-[15px] text-center'>Cloud-Based CCTV Resources</h2>
                <p className='text-center'>You’ll find a collection of helpful materials, guides, and tools to get the most out of your cloud cam products and services.</p> 
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 lg:gap-[40px] gap-[25px]'>
              <div className=''>
                  <img className='object-cover h-full w-full max-w-[550px] mx-auto' src={ImageIcons.camerastransparent} alt='' />
              </div>
              <div className='mt-[20px]'>
                <div class="flex justify-center mb-[30px] group">
                  <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                  </span>
                  <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                    <h5 class="mb-[6px] font-bold">Quick Start Guide</h5>
                    <p class="mb-[0]">Step-by-step instructions to get your cloud cam up and running quickly.</p>
                    <div className='mt-[15px]'>
                        <Link to="/contact-us" className='text-white text-[14px] bg-[#2984bd] hover:bg-[#ef7f1a] rounded-[10px] px-[20px] py-[10px] inline-flex items-center'>Download PDF</Link>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center mb-[30px] group">
                  <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                  </span>
                  <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                    <h5 class="mb-[6px] font-bold">Installation Guide</h5>
                    <p class="mb-[0]">Detailed installation instructions for various environments and mounting options.</p>
                    <div className='mt-[15px]'>
                        <Link to="/contact-us" className='text-white text-[14px] bg-[#2984bd] hover:bg-[#ef7f1a] rounded-[10px] px-[20px] py-[10px] inline-flex items-center'>Download PDF</Link>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center group">
                  <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                  </span>
                  <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                    <h5 class="mb-[6px] font-bold">User Manual</h5>
                    <p class="mb-[0]">Comprehensive manual covering all features and functions of your cloud cam. </p>
                    <div className='mt-[15px]'>
                        <Link to="/contact-us" className='text-white text-[14px] bg-[#2984bd] hover:bg-[#ef7f1a] rounded-[10px] px-[20px] py-[10px] inline-flex items-center'>Download PDF</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
                <div className='container'>
                    <div className='mb-[40px]'>
                        <h2 className='font-bold mb-[15px] text-center'>Video Tutorials</h2>
                    </div>
                    <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-[30px]'>
                        {servicesData.map((item, i) => (
                            <div key={i} className='grid sm:grid-cols-2 grid-cols-1 bg-[#fff] rounded-[10px] overflow-hidden shadow-[0_0_20px_3px_rgba(0,0,0,0.2)]'>
                                <div className='min-h-[320px]'>
                                    <img className='object-center object-cover w-full h-full' src={item.servicesImg} alt='' />
                                </div>
                                <div className='md:p-[30px] p-[25px]'>
                                    <h4 className='max-md:mb-[10px]'>{item.title}</h4>
                                    <p>{item.text}</p>
                                    <div>
                                      <Link className="inline-flex text-[16px] bg-[#2984bd] hover:bg-[#ef7f1a] text-[#fff] rounded-[10px] md:px-[24px] max-md:px-[20px] md:py-[12px] max-md:py-[10px]" to={item.readMore} onClick={windowScroll}>
                                          Watch Now
                                      </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
        </section>
    </Layout>
  )
}
const servicesData = [
    {
        title: "Unboxing and Setup",
        text: "Watch our unboxing and initial setup video.",
        servicesImg: ImageIcons.servicesImg1,
        readMore: '/contact-us',
    },
    {
        title: "Advanced Features",
        text: "Learn how to use advanced features such as motion detection and night vision. ",
        servicesImg: ImageIcons.servicesImg2,
        readMore: '/contact-us',
    },
    {
        title: "Mobile App Overview",
        text: "A walkthrough of our mobile app's features and how to use them. ",
        servicesImg: ImageIcons.servicesImg3,
        readMore: '/contact-us',
    },
    {
        title: "Firmware Updates",
        text: "Ensure your cloud cam has the latest features and security updates. ",
        servicesImg: ImageIcons.servicesImg4,
        readMore: '/contact-us',
    },
]

export default Resources
