import React, { useState } from 'react'
import Layout from '../components/shared/layout'
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoCallSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import axios from 'axios';
import stdCodes from "../data/stdCodes.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';

const Contact = () => {
  const [formInput, setFormInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    stdCode: "+91",
    phone: "",
    message: ""
  })
  const [errors, setErrors] = useState({})

  const validateForm = () => {
    const newErrors = {};

    // Validation for firstName
    if (!formInput.firstName || !formInput.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formInput.firstName)) {
      newErrors.firstName = "First Name should only contain letters";
    }

    // Validation for lastName
    if (!formInput.lastName || !formInput.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formInput.lastName)) {
      newErrors.lastName = "Last Name should only contain letters";
    }

    // Email validation
    if (!formInput.email || !formInput.email.trim()) {
      newErrors.email = "Email is required";
    } else {
      const trimmedEmail = formInput.email.trim();

      if (/\s/.test(trimmedEmail)) {
        newErrors.email = "Email should not contain spaces";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail)) {
        newErrors.email = "Invalid email format";
      }
    }

    // Subject validation
    if (!formInput.subject || !formInput.subject.trim()) {
      newErrors.subject = "Subject is required";
    }

    // Phone number validation
   if (!formInput.phone.trim()) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formInput.phone)) {
      newErrors.phone = 'Invalid phone number';
    } else if (!/^(?!.*(\d)\1{9})[6-9]\d{9}$/.test(formInput.phone)) {
      newErrors.phone = 'Invalid phone number format';
    } else if (!/^\d+$/.test(formInput.phone)) {
      newErrors.phone = 'Phone number must contain only numeric characters';
    } 

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) {
      return
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact/submit`, {
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        email: formInput.email,
        phone: `${formInput.stdCode} ${formInput.phone}`,
        message: formInput.message,
        subject: formInput.subject,
      });
      setFormInput({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        stdCodes: "",
        phone: "",
        message: ""
      });
      toast.success("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email. Please try again later.");
    }
  };


  return (
    <Layout>
      <InnerPageBanner title='Contact Us' innerPageBanner={ImageIcons.contactBanner} />
      <section>
        <div className='container'>
          <div className='flex justify-between items-center md:flex-nowrap flex-wrap gap-[20px]'>
            <div className='md:order-1 order-2 md:w-[40%] w-full md:mt-0 mt-[20px]'>
              <h2 className='md:mb-[40px] mb-[30px] font-bold'>Get In Touch</h2>
              <div class="flex justify-center group md:mb-[40px] mb-[30px]">
                <span class="flex justify-center items-center sm:h-[70px] sm:w-[70px] h-[60px] w-[60px] duration-500 rounded-[5px] shadow-[1px_1px_20px_0px_rgba(0,0,0,0.2)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                  <IoCallSharp className='sm:text-[35px] text-[30px]' />
                </span>
                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                  <h5 class="mb-[10px] font-bold]">Phone Number</h5>
                  <Link className='hover:text-[#ef7f1a]' to="tel:+91-9056111675">+91-9056111675</Link>
                </div>
              </div>

              <div class="flex justify-center group md:mb-[40px] mb-[30px]">
                <span class="flex justify-center items-center sm:h-[70px] sm:w-[70px] h-[60px] w-[60px] duration-500 rounded-[5px] shadow-[1px_1px_20px_0px_rgba(0,0,0,0.2)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                  <IoMdMail className='sm:text-[35px] text-[30px]' />
                </span>
                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                  <h5 class="mb-[10px] font-bold]">Email Address</h5>
                  <Link className='hover:text-[#ef7f1a]' to="mailto:cammycloud67@gmail.com">cammycloud67@gmail.com</Link>
                </div>
              </div>

              <div class="flex justify-center group ">
                <span class="flex justify-center items-center sm:h-[70px] sm:w-[70px] h-[60px] w-[60px] duration-500 rounded-[5px] shadow-[1px_1px_20px_0px_rgba(0,0,0,0.2)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                  <FaLocationDot className='sm:text-[35px] text-[30px]' />
                </span>
                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                  <h5 class="mb-[10px] font-bold]">Our Location​​</h5>
                  <p className='mb-0 max-w-[350px]'>B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                </div>
              </div>
            </div>

            <form className='md:order-2 order-1 md:w-[60%] w-full grid md:grid-cols-2 grid-cols-1 gap-[20px] shadow-[0_2px_20px_10px_#efefef] py-[30px] lg:px-[40px] px-[20px] rounded-[5px]'>
              <div>
                <lable className="mb-[8px] inline-block">First Name</lable>
                <input
                  type='text'
                  name='firstName'
                  value={formInput.firstName}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, firstName: e.target.value }));
                  }}
                  className='w-full h-[48px] bg-[#f1f4f5] px-[15px] rounded-[5px] focus:outline-none focus:shadow-[0_0_2px_#2984bd]' />
                {errors.firstName && (
                  <span className='text-[#ff0000] mt-[8px] inline-block text-[14p5]'>{errors.firstName}</span>
                )}
              </div>
              <div>
                <lable className="mb-[8px] inline-block">Last Name</lable>
                <input
                  type='text'
                  name='lastName'
                  value={formInput.lastName}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, lastName: e.target.value }));
                  }}
                  className='w-full h-[48px] bg-[#f1f4f5] px-[15px] rounded-[5px] focus:outline-none focus:shadow-[0_0_2px_#2984bd]' />
                {errors.lastName && (
                  <span className='text-[#ff0000] mt-[8px] inline-block text-[14p5]'>{errors.lastName}</span>
                )}
              </div>
              <div>
                <lable className="mb-[8px] inline-block">Email *</lable>
                <input
                  type='email'
                  name='email'
                  value={formInput.email}
                  required={true}
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent entering space
                    }
                  }}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, email: e.target.value }));
                  }}
                  className='w-full h-[48px] bg-[#f1f4f5] px-[15px] rounded-[5px] focus:outline-none focus:shadow-[0_0_2px_#2984bd]' />
                {errors.email && (
                  <span className='text-[#ff0000] mt-[8px] inline-block text-[14p5]'>{errors.email}</span>
                )}
              </div>
              <div>
                <lable className="mb-[8px] inline-block">Subject *</lable>
                <input
                  type='text'
                  name='subject'
                  value={formInput.subject}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, subject: e.target.value }));
                  }}
                  className='w-full h-[48px] bg-[#f1f4f5] px-[15px] rounded-[5px] focus:outline-none focus:shadow-[0_0_2px_#2984bd]' />
                {errors.subject && (
                  <span className='text-[#ff0000] mt-[8px] inline-block text-[14p5]'>{errors.subject}</span>
                )}
              </div>
              <div className='sm:col-span-2 col-span-1'>
                <lable className="mb-[8px] inline-block">Phone *</lable>
                <div className='flex'>
                  <select className="h-[48px] px-[6px] w-[110px] rounded-r-none bg-[#f1f4f5] rounded-[4px] focus:outline-none border-r border-[#d7dadb]" value={formInput.stdCode} onChange={(e) => setFormInput(prevState => ({ ...prevState, stdCode: e.target.value }))}>
                    {stdCodes.map((item, i) => (
                      <option key={i} value={item.dial_code} className="h-[20px] border-b">{item.dial_code} ({item.code})</option>
                    ))}
                  </select>
                  <input
                    type='number'
                    name='phone'
                    value={formInput.phone}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      const truncatedInput = inputText.slice(0, 10);
                      setFormInput(prevState => ({ ...prevState, phone: truncatedInput }));
                    }}
                    className='w-full rounded-l-none h-[48px] bg-[#f1f4f5] px-[15px] rounded-[5px] focus:outline-none focus:shadow-[0_0_2px_#2984bd]' />
                </div>
                {errors.phone && (
                  <span className='text-[#ff0000] mt-[8px] inline-block text-[14p5]'>{errors.phone}</span>
                )}
              </div>
              <div className='sm:col-span-2 col-span-1'>
                <lable className="mb-[8px] inline-block">Leave Us a Message</lable>
                <textarea
                  name='message'
                  value={formInput.message}
                  onChange={(e) => {
                    setFormInput(prevState => ({ ...prevState, message: e.target.value }));
                  }}
                  className='w-full h-[100px] bg-[#f1f4f5] px-[15px] py-[15px] rounded-[5px] focus:outline-none focus:shadow-[0_0_2px_#2984bd]' />
              </div>
              <div className='sm:col-span-2 col-span-1'>
                <button className='text-white md:text-[18px] text-[16px] bg-[#2984bd] hover:bg-[#ef7f1a] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-flex items-center' onClick={handleSubmit} type='submit'>Submit Message</button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className='p-0'>
        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.1786677076566!2d76.6942243!3d30.7133772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef7c84a67d9b%3A0x63c9dfc289865143!2sMy%20Cloud%20CAM!5e0!3m2!1sen!2sin!4v1705578556936!5m2!1sen!2sin" className='h-[400px] lg:h-[500px] bg-[#e8eaed]' width="100%" height="" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" ></iframe>
      </section>
      <ToastContainer />
    </Layout>
  )
}

export default Contact