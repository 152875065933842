import React from 'react'
import { Link } from 'react-router-dom'
import { MdArrowForward } from 'react-icons/md';
import { GiCctvCamera } from 'react-icons/gi';
import ImageIcons from '../imageComponent/ImageIcons';

const Platform = () => {
  return (
    <section>
      <div className='container'>
        <div className='heading_title !max-w-[900px]'>
           <div className='mb-[10px]'>
            <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
              <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
            </span>
          </div>
          <h2 className='capitalize'>A modern platform that outperforms other cloud video surveillance solutions.</h2>
        </div>
        <div className='lg:pt-[75px] pt-[45px] grid md:grid-cols-3 grid-cols-1 md:gap-[30px] gap-[80px] text-center'>
          {data.map((item, i) => (
            <div key={i} className='rounded-[5px] p-[25px] !pt-0 md:p-[30px] hover:bg-[#ef7f1a] shadow-[0_0_20px_3px_rgba(0,0,0,0.05)] group duration-500'>
              <span className='duration-700 ease-out rounded-full animate group-hover:border-[#2984bd] lg:mt-[-75px] mt-[-45px] mb-[30px] inline-block w-[90px] h-[90px] lg:h-[120px] lg:w-[120px] p-[10px] lg:p-[15px] bg-[#fff]  flex justify-center items-center border mx-auto'>
                <img src={item.icon} alt='' />
              </span>
              <h3 className='group-hover:text-white'>{item.title}</h3>
              <p className='group-hover:text-white'>{item.desc}</p>
              <Link className='group-hover:text-white text-[#ef7f1a]' to='/contact-us'><span className='flex items-center	justify-center'>{item.link} <MdArrowForward className='inline-block ml-[8px]' /></span></Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const data = [
  {
    title: "Hardware-free",
    desc: "A hardware-free, cloud-based video surveillance solution minimizes or eliminates the need for on-premises hardware, leveraging existing devices and infrastructure to provide a seamless and scalable security system.",
    icon: ImageIcons.platform1,
    link: "Replace your NVR"
  },
  {
    title: "Open Platform",
    desc: "An open platform for cloud-based CCTV surveillance refers to a system that is designed to be flexible, interoperable, and scalable, allowing integration with a wide range of third-party devices, software, and services.",
    icon: ImageIcons.platform2,
    link: "Replace your NVR"
  },
  {
    title: "World Class AI",
    desc: "World-class AI solutions in cloud-based video surveillance enhance security systems with advanced analytics, real-time monitoring, and improved decision-making.",
    icon: ImageIcons.platform3,
    link: "Replace your NVR"
  }
];

export default Platform