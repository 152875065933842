import React from 'react'
import { GiCctvCamera } from 'react-icons/gi';
import visionicon from '../../assets/icons/vision-icon.png';
import visionpic from '../../assets/images/h3-filler-img-01.jpg';
import missionicon from '../../assets/icons/mission-icon.png';
import missionpic from '../../assets/images/h3-filler-img-02.jpg';
import goalsicon from '../../assets/icons/goals-icon.png';
import goalspic from '../../assets/images/h3-filler-img-03.jpg';

const VissionMission = () => {
  return (
    <>
      <section className="relative bg-[#fff]">
            <div className='container'>
                <div className='heading_title'>
                    <div className='mb-[10px]'>
                        <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                            <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
                        </span>
                    </div>
                    <h2>Welcome To CCTV Systems</h2>
                </div>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-[40px] max-lg:gap-[24px]'>
                    <div className='max-md:mb-[30px]'>
                        <div className="flex items-center md:mb-[30px] max-md:mb-[20px]">
                            <img className="sm:w-[50px] max-sm:w-[32px]" src={visionicon} alt="Vision"/>
                            <div className="lg:pl-[15px] max-lg:pl-[10px] text-left">
                                <h4 className="mb-0 text-[#000] font-semibold">Our Vision</h4>
                            </div>
                        </div>
                        <div>
                            <img src={visionpic} className="w-full" alt="Vision"/>
                            <p className='mt-[20px] mb-0'>At MyCloudCam, we aim to redefine security for homes and businesses worldwide. We envision a future where advanced surveillance seamlessly enhances everyday life, empowering everyone to protect what matters most.</p>
                        </div>
                    </div>
                    <div className='max-md:mb-[30px]'>
                        <div className="flex items-center md:mb-[30px] max-md:mb-[20px]">
                            <img className="sm:w-[50px] max-sm:w-[32px]" src={missionicon} alt="Mission"/>
                            <div className="lg:pl-[15px] max-lg:pl-[10px] text-left">
                                <h4 className="mb-0 text-[#000] font-semibold">Our Mission</h4>
                            </div>
                        </div>
                        <div>
                            <img src={missionpic} className="w-full" alt="Mission"/>
                            <p className='mt-[20px] mb-0'>At MyCloudCam, our mission is to deliver innovative, reliable, and user-friendly security solutions that enhance safety and peace of mind for our clients, empowering them to protect their homes and businesses effectively.</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center md:mb-[30px] max-md:mb-[20px]">
                            <img className="sm:w-[50px] max-sm:w-[32px]" src={goalsicon} alt="Goals"/>
                            <div className="lg:pl-[15px] max-lg:pl-[10px] text-left">
                                <h4 className="mb-0 text-[#000] font-semibold">Our Goals</h4>
                            </div>
                        </div>
                        <div>
                            <img src={goalspic} className="w-full" alt="Goals"/>
                            <p className='mt-[20px] mb-0'>At MyCloudCam, we are committed to achieving the following goals to ensure the highest level of service and security for our clients.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default VissionMission
