import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from './pages/aboutus';
import Contact from './pages/contact';
import Products from './pages/products';
import SolutionsPage from './pages/SolutionsPage';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Faqs from './pages/Faqs';
import Resources from './pages/resources';
// import ProductsDetail from './pages/ProductsDetail';
import ThankYou from './pages/ThankYou';
import CloudVideoSurveillance from './pages/CloudVideoSurveillance';
import CloudAIandAnalytics from './pages/CloudAIandAnalytics';
import PremiumCameraSupport from './pages/PremiumCameraSupport';
import SupportedCamerasDatabase from './pages/SupportedCamerasDatabase';
import Sitemap from './pages/Sitemap';
import NotFoundPage from './pages/404';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/contact-us" exact element={<Contact />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/cloud-video-surveillance" exact element={<CloudVideoSurveillance />} />
        <Route path="/cloud-ai-and-analytics" exact element={<CloudAIandAnalytics />} />
        <Route path="/premium-camera-support" exact element={<PremiumCameraSupport />} />
        <Route path="/supported-cameras-database" exact element={<SupportedCamerasDatabase />} />
        <Route path="/solutions" exact element={<SolutionsPage />} />
        <Route path="/resources" exact element={<Resources />} />
        <Route path="/faqs" exact element={<Faqs />} />
        <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/thank-you" exact element={<ThankYou />} />
        <Route path="/sitemap" exact element={<Sitemap />} />
        <Route path="*" exact element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
   );
}

export default App;
