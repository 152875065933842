import React, { useState } from 'react'
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { IoIosArrowUp } from "react-icons/io";
import { GiCctvCamera } from 'react-icons/gi';


const Accordion = styled((props) => (<MuiAccordion disableGutters elevation={0} square {...props} />))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        marginBottom: 8,
        border: '1px solid #fff',
        backgroundColor: '#2984bd',
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<IoIosArrowUp className="text-[24px] text-white" />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#5099c7',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        padding: "3px 0",
        marginLeft: "2px",
        color: "#fff",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: '1px solid #fff',
    color: "#000",
    backgroundColor: "#f1f4f5",
    padding: "20px",

}));

const FAQ = () => {
    const [expanded, setExpanded] = useState(0);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>

            <section>
                <div className='container'>
                    <div className='heading_title'>
                        <div className='mb-[10px]'>
                            <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                                <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
                            </span>
                        </div>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div className='flex md:flex-nowrap	flex-wrap md:gap-[50px] max-md:gap-[40px]'>
                        <div className='md:w-[50%] w-full'>
                            {data.map((item, i) => (
                                <Accordion key={i} expanded={expanded === i} onChange={handleChange(i)}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography variant='body1' style={{ fontSize: '17px' }}>{item.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography style={{ fontFamily: "'Nunito Sans', sans-serif" }}>{item.answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                        <div className='md:w-[50%] w-full'>
                            <div className='mb-[24px]'>
                                <div class="mb-[7px] text-base font-medium dark:text-white">C-Mount CCTV</div>
                                <div class="w-full bg-[#fff] rounded-full border-[1px] border-[#000] p-[2px] dark:bg-gray-700">
                                    <div class="relative overflow-hidden bg-[#2984bd] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[96%] shim-green after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:content-[''] after:translate-x-[-100%] after:bg-[#fff]/[30%] after:animate-[shimmer_2s_ease-out_infinite]">96%</div>
                                </div>
                            </div>
                            <div className='mb-[24px]'>
                                <div class="mb-[7px] text-base font-medium dark:text-white">Day/Night CCTV</div>
                                <div class="w-full bg-[#fff] rounded-full border-[1px] border-[#000] p-[2px] dark:bg-gray-700">
                                    <div class="relative overflow-hidden bg-[#2984bd] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[92%] shim-green after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:content-[''] after:translate-x-[-100%] after:bg-[#fff]/[30%] after:animate-[shimmer_2.5s_ease-out_infinite]">92%</div>
                                </div>
                            </div>
                            <div className='mb-[24px]'>
                                <div class="mb-[7px] text-base font-medium dark:text-white">Infrared/Night Vision CCTV</div>
                                <div class="w-full bg-[#fff] rounded-full border-[1px] border-[#000] p-[2px] dark:bg-gray-700">
                                    <div class="relative overflow-hidden bg-[#2984bd] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[90%] shim-green after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:content-[''] after:translate-x-[-100%] after:bg-[#fff]/[30%] after:animate-[shimmer_3s_ease-out_infinite]">90%</div>
                                </div>
                            </div>
                            <div className='mb-[24px]'>
                                <div class="mb-[7px] text-base font-medium dark:text-white">Network/IP CCTV</div>
                                <div class="w-full bg-[#fff] rounded-full border-[1px] border-[#000] p-[2px] dark:bg-gray-700">
                                    <div class="relative overflow-hidden bg-[#2984bd] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[94%] shim-green after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:content-[''] after:translate-x-[-100%] after:bg-[#fff]/[30%] after:animate-[shimmer_3.5s_ease-out_infinite]">94%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const data = [
    {
        question: "Is cloud storage safe for security cameras?",
        answer: "Cloud storage is generally safer than on-site storage using an NVR or DVR. If a premium cloud data center is being used, data durability is extremely high and the chance of losing data is very low. This article explains the durability of Amazon S3 as an example, and other cloud services would offer similar data durability. Risks of hacks or unauthorized access is also very low when using cloud, as compared to an NVR stored in an unsecure location at a physical premise.",
    },
    {
        question: "How do I store my security camera footage on the cloud?",
        answer: "The best way to store footage in the cloud, is to establish a secure, direct connection from your camera directly to the cloud. This is the most efficient way to start using the cloud for storage camera footage (video) and managing your cameras online. Your cloud video surveillance partner will need to have support for your cameras to ensure there’s a seamless connection from camera to cloud.",
    },
    {
        question: "How does cloud storage work on security cameras?",
        answer: "Cloud storage is simply a location to store your camera video footage and is an alternative to using a disk (NVR, DVR) on-site. There is no cloud storage on the camera but a connection can be established from the camera to the cloud in order to move data securely for storage in the cloud.        ",
    },
    {
        question: "Do cameras have cloud storage?",
        answer: "Cameras do not have cloud storage since they are, by definition, an edge device. Many cameras have their own on-board storage such as SD cards or even a built-in solid state drive. For cameras to use the cloud as a storage location, it will need to be configured with a cloud video surveillance platform to send its data to the cloud for storage.",
    },
    {
        question: "What is cloud IP camera?",
        answer: "In general, the term “cloud IP camera” is referring an IP camera that has support for a particular cloud video surveillance platform and can be managed through a cloud service and its video data can be stored off-site in the cloud.        ",
    },

];

export default FAQ;