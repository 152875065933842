import React, { useRef } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaCircleUser } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import ImageIcons from '../imageComponent/ImageIcons';
import { GiCctvCamera } from 'react-icons/gi';

const Testimonials = () => {
    const sliderRef = useRef(null);
    let settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
    };

    return (
        <>
            <section className="testimonials bg-center bg-contain bg-no-repeat bg-[#f1f4f5]" style={{ backgroundImage: `url(${ImageIcons.testimonialsBg})` }}>
                <div className="container m-auto">
                    <div className="testimonials_titel text-center">
                        <div className='mb-[10px]'>
                            <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                                <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-[#f1f4f5] z-10 px-[10px] w-[60px]' />
                            </span>
                        </div>
                        <h2>What Our Clients Say?</h2>
                    </div>
                    <div className="max-w-[1100px] m-auto">
                        <Slider ref={sliderRef} className='px-[0] md:px-[80px] bg-top bg-auto pt-[33px] bg-no-repeat' {...settings} style={{ backgroundImage: `url(${ImageIcons.quotesIcon})` }}>
                            {testimonialData.map((item, i) => (
                                <div key={i} className='relative css'>
                                    <div>
                                        <div className="text-center max-w-[800px] m-auto">
                                            <p className="text-[18px] md:text-[20px] lg:text-[22px]">{item.text}</p>

                                            <p className="mb-[22px] md:mb-[30px]">{item.desc}</p>

                                            <div className="rating flex justify-center mb-[45px]">
                                                <span className="mx-[2.5px] text-[#ef7f1a] text-[22px]">{item.rating}</span>
                                                <span className="mx-[2.5px] text-[#ef7f1a] text-[22px]">{item.rating}</span>
                                                <span className="mx-[2.5px] text-[#ef7f1a] text-[22px]">{item.rating}</span>
                                                <span className="mx-[2.5px] text-[#ef7f1a] text-[22px]">{item.rating}</span>
                                                <span className="mx-[2.5px] text-[#ef7f1a] text-[22px]">{item.rating}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className='flex justify-center gap-[10px]'>
                            <button className="duration-700 h-[45px] w-[60px] bg-[#5ca1cd] hover:bg-[#ef7f1a] text-white cursor-pointer rounded-[8px] flex justify-center items-center sm:text-[20px] text-[18px]" onClick={() => sliderRef.current.slickPrev()}><FaArrowLeft /></button>
                            <button className="duration-700 h-[45px] w-[60px] bg-[#5ca1cd] hover:bg-[#ef7f1a] text-white cursor-pointer rounded-[8px] flex justify-center items-center sm:text-[20px] text-[18px]" onClick={() => sliderRef.current.slickNext()}><FaArrowRight /></button>
                        </div>
                    </div>
                </div>
            </section>            
        </>
    )
}

const testimonialData = [
    {
        profileImage: <FaCircleUser />,
        text: "“The installation of Wi-Fi security cameras in our home has been a game-changer. The setup was quick and hassle-free, and the ability to monitor our property remotely gives us peace of mind. The video quality is excellent, and the motion detection alerts keep us informed about any activity. We highly recommend these services for anyone looking to enhance their home security.”",
        rating: <FaStar />,
        desc: "Jashan D., Homeowner"
    },
    {
        profileImage: <FaCircleUser />,
        text: "“Managing a large facility with multiple entry points was a challenge until we integrated a custom Wi-Fi camera system. The flexibility of the wireless cameras allowed us to place them in optimal positions without worrying about cabling. The advanced features like facial recognition and real-time alerts have significantly improved our security operations. The team was professional and responsive throughout the installation process.”",
        rating: <FaStar />,
        desc: "Emily S., Apartment Complex Owner"
    },
    {
        profileImage: <FaCircleUser />,
        text: "“As the owner of an apartment complex, ensuring the safety of our residents is a top priority. The Wi-Fi camera system we implemented has been incredibly effective. The cameras cover all key areas, and the ability to access footage remotely has made management much easier. The residents appreciate the added security, and we’ve seen a noticeable reduction in incidents. Great investment!”",
        rating: <FaStar />,
        desc: "Jessica K., School Administrator"
    },

]

export default Testimonials