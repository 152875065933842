import React from 'react';
import { Link } from 'react-router-dom';
import ImageIcons from '../imageComponent/ImageIcons';
import { windowScroll } from "../utils/windowScroll";

const GetStarted = () => {
    return (
        <section className='relative after:content[""] after:absolute after:h-full after:w-full after:left-0 after:top-0 after:bg-[#2984bde0] bg-cover bg-fixed bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.getStartedBg})` }}>
            <div className='container'>
                <div className='flex lg:justify-between justify-center items-center lg:flex-nowrap flex-wrap relative z-10'>
                    <h2 className='lg:mb-0 mb-[20px] lg:w-auto w-full lg:text-left text-center text-white'>Ready to get started with MyCloudCam?</h2>
                    <Link onClick={windowScroll} className='md:text-[18px] text-[16px] bg-[#ef7f1a] hover:bg-[#2560c0] text-[#fff] hover:text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[30px] md:px-[30px] px-[25px] py-[16px] inline-flex items-center' to='/contact-us'>Get Started Now</Link>
                </div>
            </div>
        </section>
    )
}

export default GetStarted