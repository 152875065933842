import React from 'react'
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';

const TermsAndConditions = () => {
  return (
    <Layout>
      <InnerPageBanner title='Terms & Conditions' innerPageBanner={ImageIcons.contactBanner} />
      <section>
        <div className='container !max-w-[992px]'>
          <div>
            <h2 className='text-[34px] mb-[15px]'>Introduction</h2>
            <p className='mb-[30px]'>Welcome to MyCloudCam. These Terms & Conditions govern your use of our website https://www.mycloudcam.in/ and our cloud cam products and services. By accessing or using our services, you agree to be bound by these terms.</p>
          </div>
          <div>
            <h2 className='text-[34px] mb-[15px]'>Definitions</h2>
            <ul className='list-disc pl-[24px] mb-[30px]'>
              <li><span className='font-semibold'>Service:</span> Refers to the website and cloud cam products operated by MyCloudCam.</li>
              <li><span className='font-semibold'>User:</span> Any individual or entity using our Service.</li>
              <li><span className='font-semibold'>Content:</span> All text, information, images, video, audio, and other data available through our Service.</li>
            </ul>
            <h2 className='text-[34px] mb-[15px]'>Acceptance of Terms</h2>
            <p className='mb-[30px]'>By accessing or using our Service, you agree to comply with and be bound by these Terms & Conditions. If you do not agree to these terms, you may not use the Service.</p>
            <h2 className='text-[34px] mb-[15px]'>User Accounts</h2>
            <p className='mb-[30px]'>To access certain features of our Service, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to keep this information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
            
            <h2 className='text-[34px] mb-[15px]'>Use of the Service</h2>
            <p className='mb-[15px]'>You agree to use the Service only for lawful purposes and in accordance with these Terms & Conditions. You agree not to:</p>
            <ul className='list-disc pl-[24px] mb-[30px]'>
              <li>Use the Service in any way that violates any applicable local, state, national, or international law or regulation.</li>
              <li>Engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm MyCloudCam or users of the Service.</li>
              <li>Use the Service to exploit, harm, or attempt to exploit or harm minors in any way.</li>
              <li>Introduce any viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
              <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the server on which the Service is stored, or any server, computer, or database connected to the Service.</li>
            </ul>
            <h2 className='text-[34px] mb-[15px]'>Intellectual Property</h2>
            <p className='mb-[30px]'>The Service and its original content, features, and functionality are and will remain the exclusive property of MyCloudCam and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
            <h2 className='text-[34px] mb-[15px]'>User-Generated Content</h2>
            <p className='mb-[30px]'>If you upload or share any content through our Service, you grant MyCloudCam a worldwide, non-exclusive, royalty-free, perpetual, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform that content in connection with the Service.</p>
            <h2 className='text-[34px] mb-[15px]'>Privacy</h2>
            <p className='mb-[30px]'>Your use of the Service is also governed by our Privacy Policy. Please review our Privacy Policy, which describes how we collect, use, and protect your personal information.</p>
            <h2 className='text-[34px] mb-[15px]'>Termination</h2>
            <p className='mb-[30px]'>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms & Conditions. Upon termination, your right to use the Service will cease immediately.</p>
            <h2 className='text-[34px] mb-[15px]'>Limitation of Liability</h2>
            <p className='mb-[15px]'>In no event shall MyCloudCam, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojans, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not we have been informed of the possibility of such damage.</p> 
            <h2 className='text-[34px] mb-[15px]'>Governing Law</h2>
            <p className='mb-[30px]'>These Terms & Conditions shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
            <h2 className='text-[34px] mb-[15px]'>Changes to Terms & Conditions</h2>
            <p className='mb-[30px]'>We reserve the right to modify or replace these Terms & Conditions at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            <h2 className='text-[34px] mb-[15px]'>Contact Us</h2>
            <p className='mb-[10px]'>If you have any questions about these Terms & Conditions, please contact us at cammycloud67@gmail.com</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsAndConditions
