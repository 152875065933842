import React from 'react'
import { Link } from 'react-router-dom'
import { FaPlayCircle } from "react-icons/fa";
import ImageIcons from '../imageComponent/ImageIcons';
import { windowScroll } from "../utils/windowScroll";
import Slider from "react-slick";

const Hero = () => {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    fade: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <section className="hero-slider-info relative bg-[#a4cae3] xl:py-[120px] lg:py-[90px] py-[60px] bg-cover bg-center bg-repeat bg-no-repeat 2xl:bg-[left_center] xl:bg-[left_center] bg-[left_center]" style={{ backgroundImage: `url(${ImageIcons.herobgpic})` }}>
        <div className='container'>
        <Slider {...settings}>
          <div className='relative z-10'>
            <div className='flex justify-between flex-wrap items-center '>
                <div className='md:w-[49%] w-[100%] md:text-left text-center'>
                    <h1 className='font-bold text-black mb-[20px]'>Transform Your Security with Cloud Video Surveillance</h1>
                    <p className='text-black mb-[30px] md:text-[18px] text-[17px]'>Access live and recorded video footage anytime, anywhere with our cutting-edge cloud solutions.</p>
                    <Link className='text-white md:text-[18px] text-[16px] bg-[#2984bd] hover:bg-[#2560c0] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-flex items-center' to='/contact-us'>Play Video <FaPlayCircle className='ml-[10px]' /></Link>
                    <Link className='lg:ml-[20px] ml-[10px] md:text-[18px] text-[16px] text-white bg-[#ef7f1a] hover:bg-[#2984bd] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-block' to='/contact-us' onClick={windowScroll}>Contact Us</Link>
                 </div>
                 <div className='md:w-[49%] w-[100%] md:mt-0 mt-[50px]'>
                    <img className=' w-full md:max-w-[590px] max-md:max-w-[300px] mx-auto' src={ImageIcons.cameraImg} alt='' />
                 </div>
            </div>
          </div>
          <div className='relative z-10'>
            <div className='flex justify-between flex-wrap items-center '>
                <div className='w-[100%] text-center'>
                    <h1 className='font-bold text-black mb-[20px]'>Scalable Solutions for Every Need</h1>
                    <p className='text-black mb-[30px] md:text-[18px] text-[17px] max-w-[992px] mx-auto'>Whether it's your home or business, our cloud storage grows with you. No more worrying about running out of space.</p>
                    <Link className='md:text-[18px] text-[16px] text-white bg-[#ef7f1a] hover:bg-[#2984bd] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-block' to='/contact-us' onClick={windowScroll}>Get Started</Link>
                 </div>
                 <div className='w-[100%] mt-[15px]'>
                    <img className='w-full md:max-w-[650px] max-md:max-w-[500px] mx-auto' src={ImageIcons.slider2img} alt='' />
                 </div>
            </div>
          </div>
          <div className='relative z-10'>
            <div className='flex justify-between flex-wrap items-center '>
                <div className='md:w-[49%] w-[100%] md:text-left text-center'>
                    <h1 className='font-bold text-black mb-[20px]'>Your Footage, Secured and Accessible</h1>
                    <p className='text-black mb-[30px] md:text-[18px] text-[17px]'>Enjoy peace of mind with top-tier encryption and easy access from any device.</p>
                    <Link className='text-white md:text-[18px] text-[16px] bg-[#2984bd] hover:bg-[#2560c0] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-flex items-center' to='/contact-us' >Play Video <FaPlayCircle className='ml-[10px]' /></Link>
                    <Link className='lg:ml-[20px] ml-[10px] md:text-[18px] text-[16px] text-white bg-[#ef7f1a] hover:bg-[#2984bd] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-block' to='/contact-us' onClick={windowScroll}>Contact Us</Link>
                 </div>
                 <div className='md:w-[49%] w-[100%] md:mt-0 mt-[50px]'>
                    <img className='w-full md:max-w-[620px] max-md:max-w-[270px] mx-auto' src={ImageIcons.slider3img} alt='' />
                 </div>
            </div>
          </div>
          </Slider>
        </div>
    </section>
  )
}

export default Hero