import React from 'react'
import Layout from '../components/shared/layout'
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import SideBar from '../components/shared/SideBar';

const CloudVideoSurveillance = () => {
  return (
        <Layout>
            <InnerPageBanner title='Cloud Video Surveillance' innerPageBanner={ImageIcons.contactBanner} />
            <section>
                <div className='container'>
                    <div className='single-services-info flex gap-10 max-md:flex-col'>
                        <div className='md:w-3/4 relative mb-5 max-md:order-1'>
                           <div>
                            <h2>Cloud Video Surveillance</h2>
                            <p>Cloud video surveillance refers to the use of cloud computing and storage technologies to manage and store video footage from surveillance cameras. This approach leverages the scalability, flexibility, and accessibility of the cloud to enhance traditional video surveillance systems. Here are some key aspects and benefits:</p>
                            <h4>Key Aspects</h4>
                            <ul className='mb-[30px]'>
                               <li className='mb-[10px]'><b>Cloud Storage:</b> Video footage is stored in cloud servers rather than on local devices. This allows for scalable storage solutions that can accommodate large volumes of data without the need for significant on-premises infrastructure.</li>
                               <li className='mb-[10px]'><b>Remote Access:</b> Users can access live and recorded video footage from anywhere with an internet connection, using web browsers or mobile apps. This is particularly useful for businesses with multiple locations.</li> 
                               <li className='mb-[10px]'><b>Scalability:</b> Cloud-based solutions can easily scale to add more cameras or increase storage capacity without significant investment in new hardware.</li>
                               <li className='mb-[10px]'><b>Data Security:</b> Cloud providers typically offer robust security measures, including encryption, to protect video data from unauthorized access.</li>
                               <li className='mb-[10px]'><b>Integration:</b> Cloud video surveillance systems often integrate with other security systems, such as access control and alarm systems, providing a comprehensive security solution.</li>
                               <li className='mb-[0px]'><b>Advanced Features:</b> Many cloud surveillance solutions offer advanced features like video analytics, motion detection, and artificial intelligence to enhance security monitoring.</li>
                            </ul>
                            <h4>Benefits</h4>
                            <ul className='mb-[30px]'>
                               <li className='mb-[10px]'><b>Cost-Effectiveness:</b> Reduces the need for extensive on-site storage and IT infrastructure, lowering capital expenditures.</li>
                               <li className='mb-[10px]'><b>Reliability:</b> Cloud providers offer high availability and redundancy, reducing the risk of data loss due to hardware failure.</li> 
                               <li className='mb-[10px]'><b>Easy Management:</b> Simplifies the management of surveillance systems, with centralized control and automatic updates provided by the cloud service provider.</li>
                               <li className='mb-[10px]'><b>Flexibility:</b> Offers flexibility in terms of scaling, accessing, and managing video data, which can be adjusted based on changing needs.</li>
                               <li className='mb-[0px]'><b>Real-Time Monitoring:</b> Enables real-time monitoring and alerts, which can be critical for timely responses to security incidents.</li>
                            </ul>
                            <h4>Use Cases</h4>
                            <ul className='mb-[30px]'>
                               <li className='mb-[10px]'><b>Business Security:</b> Retail stores, offices, and other businesses use cloud surveillance to monitor their premises, deter theft, and ensure employee safety.</li>
                               <li className='mb-[10px]'><b>Public Safety:</b> City surveillance systems use cloud solutions to monitor public spaces and enhance safety.</li> 
                               <li className='mb-[10px]'><b>Residential Security:</b> Homeowners use cloud-based security cameras to keep an eye on their property remotely.</li>
                               <li className='mb-[10px]'><b>Transportation:</b> Airports, train stations, and other transportation hubs use cloud surveillance for security and operational efficiency.</li>
                            </ul>
                           </div>
                        </div>
                        <div className='md:w-1/4 relative mb-5 max-md:order-2 md:border-l-[1px] md:border-solid md:border-inherit'>
                            <SideBar/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
  )
}

export default CloudVideoSurveillance
