import React from 'react';
import { Link } from 'react-router-dom';
import { windowScroll } from '../utils/windowScroll';
import { GiCctvCamera } from 'react-icons/gi';
import { GoCheckCircleFill } from "react-icons/go";
import { TbDeviceCctv } from "react-icons/tb";
import ImageIcons from '../imageComponent/ImageIcons';


const Priching = () => {
    return (
        <>
            <section>
                <div className='container !max-w-[1200px]'>
                    <div className='heading_title'>
                        <div className='mb-[10px]'>
                            <span className="relative inline-flex m-auto after:content-[''] flex after:absolute after:w-[150px] after:h-0.5 after:bg-[#a4cae3] after:m-auto after:left-[50%] after:top-[50%] after:-translate-x-2/4">
                                <GiCctvCamera className='text-[32px] relative text-[#2984bd] bg-white z-10 px-[10px] w-[60px]' />
                            </span>
                        </div>
                        <h2>Our Pricing</h2>
                    </div>

                    <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-[30px] md:gap-[25px] gap-[20px] sm:max-w-[100%] max-w-[400px] mx-[auto]'>
                        {ServicesList.map((item) => (
                            <div className='overflow-hidden py-[40px] pt-[30px] md:px-[30px] px-[20px] rounded-[25px] shadow-[1px_0_50px_rgb(0_0_0_/_10%)] hover:-translate-y-2 duration-700 hover:shadow-[1px_0_50px_rgb(0_0_0_/_20%)] bg-[center_top_-90px] bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.shape1})` }}>
                                <TbDeviceCctv className="m-auto text-[60px] text-[#85b9da] mb-[50px]" />
                                <h2 className=' font-bold text-[#ef7f1a] mb-[10px] text-center'>₹{item.price}</h2>
                                <h4 className="text-[22px] mb-[30px] text-center">{item.title}</h4>

                                <div className='pt-35px] relative after:content-[""]  after:absolute after:z-[-1] after:right-[-90px] after:h-3/5 after:rotate-[19deg] after:w-[105px] after:transition-[0.1s] after:duration-[all] after:ease-[ease-in] after:rounded-[30px] after:bottom-[50%] after:!bg-[#f0f6ff]'>
                                    <div className='text-[18px] mb-[20px]' >
                                        {item.dataList.map((list, i) => (
                                            <ul key={i} className="grid gap-[10px] sm:gap-[12px] md:gap-[15px]">
                                                <li className='flex mb-[20px]'>
                                                    <GoCheckCircleFill className="text-[26px] mr-[8px] text-[#5ca1cd]" />{list.listItem}
                                                </li>
                                            </ul>
                                        ))}
                                    </div>

                                    <div className='text-center'>
                                        <Link className="text-white md:text-[18px] text-[16px] bg-[#2984bd] hover:bg-[#ef7f1a] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[15px] inline-flex items-center" to="/contact-us" onClick={windowScroll}>
                                            Order Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

const ServicesList = [
    {
        title: "BRONZE",
        price: 399,
        dataList: [
            {
                listItem: "Upto 50 GB Cloud Storage",
            },
            {
                listItem: "No Frame Drop",
            },
            {
                listItem: "256-bit End to End Encyption",
            },
        ],
    },
    {
        title: "SILVER",
        price: 799,
        dataList: [
            {
                listItem: "Upto 100 GB Cloud Storage",
            },
            {
                listItem: "No Frame Drop",
            },
            {
                listItem: "256-bit End to End Encyption",
            },
        ],
    },
    {
        title: "GOLD",
        price: 1499,
        dataList: [
            {
                listItem: "Upto 200 GB Cloud Storage",
            },
            {
                listItem: "No Frame Drop (Upto 1080p)",
            },
            {
                listItem: "256-bit End to End Encyption",
            },
        ],
    },

]

export default Priching;