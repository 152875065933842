import React from 'react';
import Layout from '../components/shared/layout';
import Hero from "../components/home/hero";
import Platform from '../components/home/platform';
import EnhancedCamera from '../components/home/enhancedCamera';
import Testimonials from '../components/home/testimonials';
import CloudCam from '../components/home/cloudCam';
import Services from '../components/home/services';
import GetStarted from '../components/home/getStarted';
import FAQ from '../components/home/faq';
import Priching from '../components/home/priching';
import AboutSection from '../components/home/aboutSection';

const Home = () => {
  return (
    <Layout>
      <Hero />
      <Platform />
      <CloudCam />
      <EnhancedCamera />
      <AboutSection />
      <Services />
      <GetStarted />
      <Priching />
      <Testimonials />
      <FAQ />
    </Layout>
  )
}

export default Home