import logo from "../../assets/images/logo.png";
import footerBg from "../../assets/images/footer-bg.png";
import banenrBg from "../../assets/images/banner_bg.png";
import cameraImg from "../../assets/images/camera_img.png";
import cam1 from "../../assets/images/cloud_cam/cam1.png";
import cam2 from "../../assets/images/cloud_cam/cam2.png";
import cam3 from "../../assets/images/cloud_cam/cam3.png";
import cam4 from "../../assets/images/cloud_cam/cam4.png";
import mobileImg1 from "../../assets/images/mobile_images/mob1.png";
import faqImage from "../../assets/images/faq.png";
import getStartedBg from "../../assets/images/get_started_bg.png";
import platform1 from "../../assets/icons/platform/platform1.png";
import platform2 from "../../assets/icons/platform/platform2.png";
import platform3 from "../../assets/icons/platform/platform3.png";
import servicesImg1 from "../../assets/images/services/services1.jpg";
import servicesImg2 from "../../assets/images/services/services2.jpg";
import servicesImg3 from "../../assets/images/services/services3.jpg";
import servicesImg4 from "../../assets/images/services/services4.jpg";
import servicesImg5 from "../../assets/images/services/services5.jpg";
import quotesIcon from "../../assets/icons/quotes_icon.svg";
import testimonialsBg from "../../assets/images/testimonials-bg.png";
import contactBanner from "../../assets/images/contact_banner.jpg";
import shape1 from "../../assets/icons/shape-1.png";
import aboutSecBg from "../../assets/images/about_sec_bg.jpg";
import cloudBasedCctv from "../../assets/images/cloud_based_cctv.png";
import aboutimg from "../../assets/images/about_img.png";
import camerastransparent from "../../assets/images/cameras_transparent.jpg";
import pdficon from "../../assets/images/pdf-icon.webp";
import contactpic from "../../assets/images/bg-cover-imh.webp";
import survilencepic from "../../assets/images/survilence-camera.webp";
import slider2img from "../../assets/images/slider2-bg.png";
import slider3img from "../../assets/images/camera-home.png";
import herobgpic from "../../assets/images/herobg.png";
import survilencecamera from '../../assets/images/survilence-camera.webp';
import cloudrecording from "../../assets/images/cloud-recording.png";
import mycloudlogo from "../../assets/images/logo-org.png";


const ImageIcons = {
  logo,
  mycloudlogo,
  banenrBg,
  cameraImg,
  cam1,
  cam2,
  cam3,
  cam4,
  mobileImg1,
  faqImage,
  getStartedBg,
  platform1,
  platform2,
  platform3,
  servicesImg1,
  servicesImg2,
  servicesImg3,
  servicesImg4,
  servicesImg5,
  quotesIcon,
  testimonialsBg,
  footerBg,
  contactBanner,
  shape1,
  aboutSecBg,
  cloudBasedCctv,
  aboutimg,
  camerastransparent,
  pdficon,
  contactpic,
  survilencepic,
  slider2img,
  slider3img,
  herobgpic,
  survilencecamera,
  cloudrecording,
};

export default ImageIcons;