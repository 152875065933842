import React from 'react'
import { IoMdCheckmark } from "react-icons/io";
import ImageIcons from '../imageComponent/ImageIcons';

const CloudCam = () => {
    return (
        <>
            <section className='bg-[#f1f4f5]'>
                <div className='container !max-w-[1200px]'>
                    <div className='grid md:grid-cols-2  grid-cols-1 lg:gap-[5%] gap-[25px]'>
                        <div className='mb-0 mb-[20px]'>
                            <h2 className='font-bold mb-[40px]'>What is MyCloudCam?</h2>
                            <div class="flex justify-center lg:mb-[40px] mb-[30px] group">
                                <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                                </span>
                                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                                    <h5 class="mb-[6px] font-bold">Cloud Storage</h5>
                                    <p class="mb-[0]">Videos are stored in the cloud, ensuring that footage is accessible even if the camera is damaged or stolen.</p>
                                </div>
                            </div>
                            <div class="flex justify-center lg:mb-[40px] mb-[30px] group">
                                <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                                </span>
                                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                                    <h5 class="mb-[6px] font-bold">Remote Access</h5>
                                    <p class="mb-[0]">Users can view live footage and recorded videos from anywhere using a smartphone app or web browser.</p>
                                </div>
                            </div>
                            <div class="flex justify-center lg:mb-[40px] mb-[30px] group">
                                <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                                </span>
                                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                                    <h5 class="mb-[6px] font-bold">High-Quality Video</h5>
                                    <p class="mb-[0]">High-definition video recording (e.g., 1080p) for clear and detailed footage.</p>
                                </div>
                            </div>
                            <div class="flex justify-center group">
                                <span class="flex justify-center items-center sm:h-[60px] sm:w-[60px] h-[50px] w-[50px] duration-500 rounded-full shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[60px] text-[#2984bd] group-hover:text-[#fff]">
                                    <IoMdCheckmark className='sm:text-[30px] text-[25px]' />
                                </span>
                                <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_60px)] w-[calc(100%_-_50px)]">
                                    <h5 class="mb-[6px] font-bold">Integration with Smart Home Devices</h5>
                                    <p class="mb-[0]">Compatibility with other smart home devices and platforms (e.g., Amazon Alexa, Google Assistant) for integrated home automation.</p>
                                </div>
                            </div>
                        </div>

                        <div className='grid grid-cols-2'>
                            <div className=' '>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam1} alt='' />
                            </div>
                            <div className=' '>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam2} alt='' />
                            </div>
                            <div className=' '>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam3} alt='' />
                            </div>
                            <div className=' '>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam4} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CloudCam