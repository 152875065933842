import React from 'react'
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { Link } from 'react-router-dom'

const ThankYou = () => {
  return (
    <>
      <Layout>
        <InnerPageBanner title='Thank You' innerPageBanner={ImageIcons.contactBanner} />
        <section className='bg-[#fff]'>
          <div className='container'>
              <div className="md:p-[50px] max-md:p-[30px]">
                <div className="flex flex-col items-center space-y-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <h2 className="text-center font-bold">Thank You !</h2>
                  <p className='text-center'>Thank you for your interest! Check your email for a link to the guide.</p>
                  <div className='!mt-[30px]'>
                    <Link className='md:text-[18px] text-[16px] text-white bg-[#2984bd] hover:bg-[#ef7f1a] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-block' to='/'>Return to Home</Link>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default ThankYou
