import React, { useState } from 'react'
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { IoIosArrowUp } from "react-icons/io";


const Accordion = styled((props) => (<MuiAccordion disableGutters elevation={0} square {...props} />))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        marginBottom: 8,
        border: '1px solid #fff',
        backgroundColor: '#2984bd',
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<IoIosArrowUp className="text-[24px] text-white" />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#5099c7',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
         padding: "3px 0",
        marginLeft: "2px",
        color: "#fff",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: '1px solid #fff',
    color: "#000",
    backgroundColor: "#f1f4f5",
    padding: "20px",

}));

const Faqs = () => {
    const [expanded, setExpanded] = useState(0);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
  return (
    <Layout>
        <InnerPageBanner title='FAQs' innerPageBanner={ImageIcons.contactBanner} />
        <section>
                <div className='container !max-w-[992px]'>
                    <div className='heading_title'>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div className='flex md:flex-nowrap	flex-wrap  items-center gap-[40px]'>
                        <div className='w-full'>
                            {data.map((item, i) => (
                                <Accordion key={i} expanded={expanded === i} onChange={handleChange(i)}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography variant='body1' style={{ fontSize: '17px' }}>{item.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography style={{ fontFamily: "'Nunito Sans', sans-serif" }}>{item.answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                        {/* <div className='md:w-[40%] w-full md:block hidden'>
                            <img className='md:ml-auto max-h-[450px]' src={ImageIcons.faqImage} alt='' />
                        </div> */}
                    </div>
                </div>
            </section>
    </Layout>
  )
}
const data = [
    {
        question: "Is cloud storage safe for security cameras?",
        answer: "Cloud storage is generally safer than on-site storage using an NVR or DVR. If a premium cloud data center is being used, data durability is extremely high and the chance of losing data is very low. This article explains the durability of Amazon S3 as an example, and other cloud services would offer similar data durability. Risks of hacks or unauthorized access is also very low when using cloud, as compared to an NVR stored in an unsecure location at a physical premise.",
    },
    {
        question: "How do I store my security camera footage on the cloud?",
        answer: "The best way to store footage in the cloud, is to establish a secure, direct connection from your camera directly to the cloud. This is the most efficient way to start using the cloud for storage camera footage (video) and managing your cameras online. Your cloud video surveillance partner will need to have support for your cameras to ensure there’s a seamless connection from camera to cloud.",
    },
    {
        question: "How does cloud storage work on security cameras?",
        answer: "Cloud storage is simply a location to store your camera video footage and is an alternative to using a disk (NVR, DVR) on-site. There is no cloud storage on the camera but a connection can be established from the camera to the cloud in order to move data securely for storage in the cloud.        ",
    },
    {
        question: "Do cameras have cloud storage?",
        answer: "Cameras do not have cloud storage since they are, by definition, an edge device. Many cameras have their own on-board storage such as SD cards or even a built-in solid state drive. For cameras to use the cloud as a storage location, it will need to be configured with a cloud video surveillance platform to send its data to the cloud for storage.",
    },
    {
        question: "What is cloud IP camera?",
        answer: "In general, the term “cloud IP camera” is referring an IP camera that has support for a particular cloud video surveillance platform and can be managed through a cloud service and its video data can be stored off-site in the cloud.        ",
    },
    {
        question: "How do I set up a cloud IP camera?",
        answer: "Cloud video surveillance vendors offer easy setup for cloud IP cameras, including the ability to add your IP camera through a wizard-style setup procedure, either on the web or through an Android or iOS mobile app.        ",
    },
    {
        question: "What IP Cameras work with Camcloud?",
        answer: "Camcloud video surveillance is an open platform that works with almost any IP camera. We offer plug-and-play camera support for leading brands such as Axis, Amcrest, Hanwha, Hikvision and Speco. In addition, almost any H.264 camera can be added to the cloud, all through an easy setup wizard. Learn more about our open platform camera support.        ",
    },

];
export default Faqs
