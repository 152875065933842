import React from 'react'
import Layout from '../components/shared/layout'
import ImageIcons from '../components/imageComponent/ImageIcons';
import InnerPageBanner from '../components/shared/innerPageBanner';
import SideBar from '../components/shared/SideBar';

const PremiumCameraSupport = () => {
  return (
        <Layout>
            <InnerPageBanner title='Premium Camera Support' innerPageBanner={ImageIcons.contactBanner} />
            <section>
                <div className='container'>
                    <div className='single-services-info flex gap-10 max-md:flex-col'>
                        <div className='md:w-3/4 relative mb-5 max-md:order-1'>
                           <div>
                            <h2>Premium Camera Support</h2>
                            <p>Enhance your surveillance experience with MyCloudCam Premium Camera Support. Designed to provide superior performance and exceptional reliability, our premium support service ensures that your security system operates at its best. From expert assistance to advanced features, MyCloudCam Premium Camera Support offers everything you need for top-tier protection.</p>
                            <h4>Key Benefits</h4>
                            <ul className='list-decimal pl-[20px] mb-[30px]'>
                               <li className='mb-[10px]'><b>Expert Technical Assistance</b> 
                                    <ul className='list-disc mt-[5px] pl-[20px]'>
                                        <li className='mb-[5px]'><b>24/7 Support:</b> Access our team of experienced technicians any time, day or night, to resolve any issues or answer your questions.</li>
                                        <li><b>Personalized Help:</b> Receive tailored assistance and guidance to optimize your camera setup and performance.</li>
                                    </ul>
                               </li>
                               <li className='mb-[10px]'><b>Advanced Features</b>
                                    <ul className='list-disc mt-[5px] pl-[20px]'>
                                        <li className='mb-[5px]'><b>Extended Cloud Storage:</b> Enjoy increased cloud storage capacity to keep more footage for longer periods.</li>
                                        <li><b>Priority Updates:</b> Get early access to the latest features and improvements, ensuring your system is always up to date.</li>
                                    </ul>
                               </li> 
                               <li className='mb-[10px]'><b>Enhanced Camera Performance</b>
                                    <ul className='list-disc mt-[5px] pl-[20px]'>
                                        <li className='mb-[5px]'><b>High-Resolution Streaming:</b> Experience ultra-clear video quality with high-resolution streaming capabilities.</li>
                                        <li><b>Superior Night Vision:</b> Benefit from advanced night vision technology for clear images even in the darkest environments.</li>
                                    </ul>
                               </li> 
                               <li className='mb-[0px]'><b>Proactive Monitoring</b>
                                    <ul className='list-disc mt-[5px] pl-[20px]'>
                                        <li className='mb-[5px]'><b>Health Checks:</b> Regular system health checks to ensure your cameras are functioning optimally.</li>
                                        <li><b>Performance Analytics:</b> Detailed performance analytics to monitor camera functionality and identify potential issues before they become problems.</li>
                                    </ul>
                               </li>
                            </ul>
                            <h4>How It Works</h4>
                            <ul className='mb-[30px]'>
                               <li className='mb-[10px]'><b>Subscription Enrollment:</b> Sign up for the Premium Camera Support service through the MyCloudCam app or website.</li>
                               <li className='mb-[10px]'><b>Access Premium Features:</b> Immediately start enjoying the benefits of extended cloud storage, priority updates, and enhanced performance features.</li> 
                               <li className='mb-[10px]'><b>Receive Expert Support:</b> Contact our support team at any time for personalized assistance and technical help.</li>
                               <li className='mb-[10px]'><b>Regular Monitoring and Updates:</b> Benefit from regular system health checks and performance analytics to maintain optimal camera functionality.</li>
                            </ul>
                            <h4>Why Choose Premium Camera Support?</h4>
                            <ul className='mb-[30px]'>
                               <li className='mb-[10px]'><b>Reliability:</b> Ensure your security system is always performing at its best with expert support and advanced features.</li>
                               <li className='mb-[10px]'><b>Convenience:</b> Enjoy peace of mind with 24/7 access to technical assistance and proactive monitoring.</li> 
                               <li className='mb-[10px]'><b>Value:</b> Maximize the value of your MyCloudCam system with enhanced performance and extended storage.</li>
                            </ul>
                           </div>
                        </div>
                        <div className='md:w-1/4 relative mb-5 max-md:order-2 md:border-l-[1px] md:border-solid md:border-inherit'>
                            <SideBar/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
  )
}

export default PremiumCameraSupport
