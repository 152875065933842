import React from "react";
import Layout from "../components/shared/layout";
import Button from "../components/shared/button";


const NotFoundPage = () => {
    return (
        <Layout>
            <section className='bg-[linear-gradient(0deg,#f7fbfd_0%,#359dd0_100%)] md:py-[120px] py-[70px]'>
                <div className='container'>
                    <div className="text-center">
                        <h1 className="md:text-[200px] text-[100px] md:leading-[200px] leading-[100px] pt-[40px] text-white">4<span className="animate-bounce inline-block text-[#ef7f1a]">0</span>4</h1>
                        <h3 className="mb-[30px]">Oops! Something is wrong.</h3>

                        <Button hrefLink='/' title='Go to Home' />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage