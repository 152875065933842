import React from 'react'
import { IoMdCheckmark } from "react-icons/io";
import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom'


const AboutUs = () => {
    return (
        <>
            <section className='bg-[#f1f4f5]'>
                <div className='container !max-w-[1200px]'>
                    <div className='grid md:grid-cols-2  grid-cols-1 lg:gap-[30px] gap-[20px]'>
                        <div className='mb-0 mb-[20px]'>
                            <h2 className='font-bold mb-[15px]'>Welcome to MyCloudCam</h2>
                            <p class="mb-[30px]">Your trusted partner in advanced surveillance solutions. We specialize in providing state-of-the-art cloud-based CCTV systems tailored to meet the unique security needs of homes, businesses, and large facilities. Our mission is to deliver top-notch security solutions that combine cutting-edge technology with user-friendly features, ensuring peace of mind for our clients.</p>
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-[15px]'>
                                    <div class="flex justify-center items-center group mb-[10px]">
                                        <span class="flex justify-center items-center sm:h-[40px] sm:w-[40px] h-[30px] w-[30px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[55px] text-[#2984bd] group-hover:text-[#fff]">
                                            <IoMdCheckmark className='sm:text-[24px] text-[25px] -rotate-45' />
                                        </span>
                                        <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                            <h5 class="mb-0 text-[16px]">Cloud-Based CCTV Surveillance</h5>
                                        </div>
                                    </div>
                                    <div class="flex justify-center items-center group mb-[10px]">
                                        <span class="flex justify-center items-center sm:h-[40px] sm:w-[40px] h-[30px] w-[30px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[55px] text-[#2984bd] group-hover:text-[#fff]">
                                            <IoMdCheckmark className='sm:text-[24px] text-[25px] -rotate-45' />
                                        </span>
                                        <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                            <h5 class="mb-0 text-[16px]">Maintenance & Support</h5>
                                        </div>
                                    </div>
                                    <div class="flex justify-center items-center group mb-[10px]">
                                        <span class="flex justify-center items-center sm:h-[40px] sm:w-[40px] h-[30px] w-[30px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[55px] text-[#2984bd] group-hover:text-[#fff]">
                                            <IoMdCheckmark className='sm:text-[24px] text-[25px] -rotate-45' />
                                        </span>
                                        <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                            <h5 class="mb-0 text-[16px]">Wi-Fi Camera Security</h5>
                                        </div>
                                    </div>
                                    <div class="flex justify-center items-center group mb-[10px]">
                                        <span class="flex justify-center items-center sm:h-[40px] sm:w-[40px] h-[30px] w-[30px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[55px] text-[#2984bd] group-hover:text-[#fff]">
                                            <IoMdCheckmark className='sm:text-[24px] text-[25px] -rotate-45' />
                                        </span>
                                        <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                            <h5 class="mb-0 text-[16px]">Custom Solutions</h5>
                                        </div>
                                    </div>
                                    <div class="flex justify-center items-center group mb-[10px]">
                                        <span class="flex justify-center items-center sm:h-[40px] sm:w-[40px] h-[30px] w-[30px] duration-500 rounded-[10px] rotate-45 shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] group-hover:bg-[#ef7f1a] text-[55px] text-[#2984bd] group-hover:text-[#fff]">
                                            <IoMdCheckmark className='sm:text-[24px] text-[25px] -rotate-45' />
                                        </span>
                                        <div class="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                            <h5 class="mb-0 text-[16px]">Installation Services</h5>
                                        </div>
                                    </div>
                            </div>
                            <div className='mt-[30px]'>
                                <Link className='md:text-[18px] text-[16px] text-white bg-[#2984bd] hover:bg-[#ef7f1a] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-block' to='/contact-us'>Contact Us</Link>
                            </div>  
                        </div>
                        <div className='block'>
                            <div>
                                <img className='object-cover h-full w-full' src={ImageIcons.aboutimg} alt='' />
                            </div>
                            {/* <div>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam2} alt='' />
                            </div>
                            <div>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam3} alt='' />
                            </div>
                            <div>
                                <img className='object-cover h-full w-full' src={ImageIcons.cam4} alt='' />
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs
