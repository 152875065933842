import React from 'react';
import Layout from '../components/shared/layout';
import Testimonials from '../components/home/testimonials';
import AboutUs from '../components/aboutus/AboutUs';
import GetStarted from '../components/home/getStarted';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import LeadershipTeams from '../components/aboutus/LeadershipTeam';
import VissionMission from '../components/aboutus/VissionMission';


const AboutUS = () => {
  return (
    <Layout>
      <InnerPageBanner title='About Us' innerPageBanner={ImageIcons.contactBanner} />
      <AboutUs />
      <VissionMission/>
      <GetStarted />
      <LeadershipTeams/>
      <Testimonials />
    </Layout>
  )
}

export default AboutUS
